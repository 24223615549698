import { useState, useEffect } from "react";

// Third party imports
import { useQuery } from "react-query";

// Util imports
import { client } from "utils/api-client";
import { Notification } from "utils/notification";

// Context imports
import { useMykademyDataContext, useVleTokenContext } from "context";
import { useAuthorizeLearnerPathway } from "hooks/useAuthorizeLearnerPathway";
import showGamificationMessage from "utils/showGamificationMessage";

function useGetPathwayDetails({ pathwayId }: { pathwayId: string }) {
  const [pathway, setPathway] = useState([]);

  const { vleToken } = useVleTokenContext();
  const {
    mykademyUserData: {
      userData: { mk_member_id },
    },
  } = useMykademyDataContext();

  const { pathwayToken, isLoading: isAuthorizing } =
    useAuthorizeLearnerPathway();

  const getPathwayDetails = () => {
    return client({
      method: "get",
      endpoint: "api/learning-pathway/enrolled-courses",
      optional: {
        token: vleToken,
        params: {
          token: pathwayToken,
          learner: mk_member_id,
          pathway: pathwayId,
        },
      },
    });
  };

  const { data, error, isSuccess, isLoading, isError } = useQuery(
    ["pathwayDetails", { pathwayId }],
    getPathwayDetails,
    {
      refetchOnWindowFocus: false,
      enabled: !!pathwayToken && !!pathwayId,
    }
  );

  useEffect(() => {
    if (isSuccess) {
      const recentPathway = data?.data?.data?.data?.map((item: any) => ({
        id: item?.pathwayId,
        pathwayEnrolmentId: item?.pathwayEnrolmentId,
        title: item.title,
        pathway_progress: item.pathway_progress,
        componentCounts: item.componentCounts,
        // eslint-disable-next-line array-callback-return
        courses: item.components?.map((component: any) => {
          if (
            component &&
            component.metadata &&
            component.metadata.course
            // && component.skip === false
          ) {
            component.metadata.course["enable_component"] = !component.restrict;
            component.metadata.course["recent_course"] =
              component.isRecentComponent;

            return {
              ...component.metadata,
              metadataId: component?._id,
              subComponents: component.subComponents,
              recent_course: component?.isRecentComponent,
              type: component?.type,
            };
          } else if (
            component &&
            component.metadata &&
            component.metadata.document
          ) {
            component.metadata.document["enable_component"] =
              !component.restrict;
            component.metadata.document["recent_course"] =
              component.isRecentComponent;

            return {
              ...component.metadata,
              metadataId: component?._id,
              recent_course: component?.isRecentComponent,
              subComponents: component.subComponents,
              type: component?.type,
              title: component?.title,
            };
          }
        }),
        recent_course: item.recent_course,
        recent_components: item.recent_components,
      }));

      if (recentPathway) {
        console.log("data", data);
        setPathway(recentPathway[0]);
        showGamificationMessage({
          gamification: data?.data?.data?.gamification,
        });
      }
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (isError) {
      console.log({ error });

      Notification({
        message: "Unable to get pathway details, please try again later",
        type: "warning",
        title: "Warning",
      });
    }
  }, [error, isError]);

  return {
    pathway,
    isLoading: isLoading || isAuthorizing,
    isSuccess,
  };
}

export { useGetPathwayDetails };
