import * as React from "react";
import { SsoProvider } from "./ssoContext";
import { LegacyProvider } from "./legacyContext";
import { DomainProvider } from "./domainContext";
import { PowerBiProvider } from "./powerBiContext";
import { CartContextProvider } from "./cartContext";
import { UserRoleProvider } from "./userRoleContext";
import { VleTokenProvider } from "./vleTokenContext";
import { CheckboxProvider } from "./checkboxContext";
import { OnboardingProvider } from "./onboardingContext";
import { PathwayProvider } from "./pathwayContext";
import { GamificationProvider } from "./gamificationContext";
import { CourseHeaderProvider } from "./courseHeaderContext";
import { MykademyDataProvider } from "./mykademyDataContext";
import { AddedCoursesProvider } from "./addedCoursesContext";
import { PathwayTokenProvider } from "./pathwayTokenContext";
import { CoursesFilterProvider } from "./coursesFilterContext";
import { PortfolioDataProvider } from "./portfolioDataContext";
import { MykademyAdminProvider } from "./mykademyAdminContext";
import { SelectedOptionProvider } from "./selectedOptionContext";
import { LearnersFilterProvider } from "./learnersFilterContext";
import { CertificateContextProvider } from "./certificateContext";
import { PaymentResponseProvider } from "./paymentResponseContext";
import { NewMembersFilterProvider } from "./newMembersFilterContext";
import { MarketPlaceFilterProvider } from "./marketPlaceFilterContext";
import { CourseLibraryDataProvider } from "./courseLibraryDataContext";
import { RecentCoursesDataProvider } from "./recentCoursesDataContext";
import { ActiveMembersFilterProvider } from "./activeMembersFilterContext";
import { TrendingCoursesDataProvider } from "./trendingCoursesDataContext";
import { VideoClassRoomFilterProvider } from "./videoClassRoomDataContext";
import { PathwayLibraryFilterProvider } from "./pathwayLibraryFilterContext";
import { CoursesPopularityFilterProvider } from "./coursesPopularityContext";
import { ActivityHistoryFilterProvider } from "./activityHistoryFilterContext";
import { PurchaseHistoryFilterProvider } from "./purchaseHistoryFilterContext";
import { LearnerCourseFilterContextProvider } from "./learnerCourseFilterContext";

/**  --- Read the instructions.md file in this folder before using context --- */
function AppProviders({ children }: any) {
  return (
    <SelectedOptionProvider>
      <MykademyDataProvider>
        <PortfolioDataProvider>
          <OnboardingProvider>
            <UserRoleProvider>
              <VleTokenProvider>
                <LegacyProvider>
                  <DomainProvider>
                    <PathwayProvider>
                      <ActivityHistoryFilterProvider>
                        <GamificationProvider>
                          <AddedCoursesProvider>
                            <CourseLibraryDataProvider>
                              <MarketPlaceFilterProvider>
                                <PaymentResponseProvider>
                                  <CertificateContextProvider>
                                    <PathwayLibraryFilterProvider>
                                      <PurchaseHistoryFilterProvider>
                                        <CoursesFilterProvider>
                                          <RecentCoursesDataProvider>
                                            <TrendingCoursesDataProvider>
                                              <PowerBiProvider>
                                                <PathwayTokenProvider>
                                                  <ActiveMembersFilterProvider>
                                                    <VideoClassRoomFilterProvider>
                                                      <NewMembersFilterProvider>
                                                        <CoursesPopularityFilterProvider>
                                                          <LearnersFilterProvider>
                                                            <MykademyAdminProvider>
                                                              <CourseHeaderProvider>
                                                                <LearnerCourseFilterContextProvider>
                                                                  <CartContextProvider>
                                                                    <SsoProvider>
                                                                      <CheckboxProvider>
                                                                        {
                                                                          children
                                                                        }
                                                                      </CheckboxProvider>
                                                                    </SsoProvider>
                                                                  </CartContextProvider>
                                                                </LearnerCourseFilterContextProvider>
                                                              </CourseHeaderProvider>
                                                            </MykademyAdminProvider>
                                                          </LearnersFilterProvider>
                                                        </CoursesPopularityFilterProvider>
                                                      </NewMembersFilterProvider>
                                                    </VideoClassRoomFilterProvider>
                                                  </ActiveMembersFilterProvider>
                                                </PathwayTokenProvider>
                                              </PowerBiProvider>
                                            </TrendingCoursesDataProvider>
                                          </RecentCoursesDataProvider>
                                        </CoursesFilterProvider>
                                      </PurchaseHistoryFilterProvider>
                                    </PathwayLibraryFilterProvider>
                                  </CertificateContextProvider>
                                </PaymentResponseProvider>
                              </MarketPlaceFilterProvider>
                            </CourseLibraryDataProvider>
                          </AddedCoursesProvider>
                        </GamificationProvider>
                      </ActivityHistoryFilterProvider>
                    </PathwayProvider>
                  </DomainProvider>
                </LegacyProvider>
              </VleTokenProvider>
            </UserRoleProvider>
          </OnboardingProvider>
        </PortfolioDataProvider>
      </MykademyDataProvider>
    </SelectedOptionProvider>
  );
}

export default AppProviders;
