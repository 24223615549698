import { useState, useEffect } from "react";

import { client } from "utils/api-client";

import { useQuery } from "react-query";

import { Notification } from "utils/notification";

import { useVleTokenContext, useSsoFilterStateContext } from "context";

function useGetMsUsers() {
	const [users, setUsers] = useState([]);
	const [getMsUserLoader, setGetMsUserLoader] = useState(false);

	const { vleToken } = useVleTokenContext();

	const filterStateSso = useSsoFilterStateContext();

	const getMsUsers = ({ filterStateSso }: any) => {
		const apiBody = {
			accessToken: localStorage.getItem("graph_access_token"),
		};

		return client({
			method: "post",
			endpoint: `api/member/get-ms-learners?search=${filterStateSso}`,
			optional: {
				token: vleToken,
				data: apiBody,
			},
		});
	};

	const { data, isFetching, isSuccess, isError, error } = useQuery(
		["users", { filterStateSso }],
		() => getMsUsers({ filterStateSso }),
		{
			refetchOnWindowFocus: false,
		}
	);

	useEffect(() => {
		if (isFetching) {
			setGetMsUserLoader(true);
		}
	}, [isFetching]);

	useEffect(() => {
		if (isSuccess) {
			setGetMsUserLoader(false);

			if (data) {
				// @ts-ignore
				setUsers(data?.data?.data);
			}
		}
	}, [isSuccess, data]);

	useEffect(() => {
		if (isError) {
			setGetMsUserLoader(false);

			Notification({
				message: "Unable to get learners, please try again later",
				type: "warning",
				title: "Warning",
			});
		}
	}, [isError]);

	useEffect(() => {
		if (error) {
		}
	}, [error]);

	return { users, setUsers, getMsUserLoader, isError };
}

export { useGetMsUsers };
