import React, { useState, useMemo } from "react";

interface GamificationContextParams {
  reputationActivities: any;
  badgeActivities: any;
  setReputationActivities: (state: any) => void;
  setBadgeActivities: (state: any) => void;
  badgeLabel: any;
  setBadgeLabel: (state: any) => void;
  memberDetails: any;
  setMemberDetails: (state: any) => void;
}

const GamificationContext = React.createContext<
  GamificationContextParams | undefined
>(undefined);

GamificationContext.displayName = "GamificationContext";

function GamificationProvider(props: any) {
  const [reputationActivities, setReputationActivities] = useState<any>(null);
  const [badgeActivities, setBadgeActivities] = useState<any>(null);
  const [badgeLabel, setBadgeLabel] = useState<any>(null);
  const [memberDetails, setMemberDetails] = useState<any>(null);

  const value = useMemo(() => {
    return {
      badgeLabel,
      setBadgeLabel,
      memberDetails,
      badgeActivities,
      setMemberDetails,
      setBadgeActivities,
      reputationActivities,
      setReputationActivities,
    };
  }, [badgeActivities, badgeLabel, memberDetails, reputationActivities]);

  return <GamificationContext.Provider value={value} {...props} />;
}

function useGamificationContext() {
  const context = React.useContext(GamificationContext);

  if (context === undefined) {
    throw new Error(
      `useGamificationContext must be used within a GamificationProvider`
    );
  }

  return context;
}

export { GamificationProvider, useGamificationContext };
