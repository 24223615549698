import {
  useGamificationContext,
  usePathwayTokenContext,
  useVleTokenContext,
} from "context";
import { useEffect } from "react";

// Third party imports
import { useQuery } from "react-query";

// Util imports
import { client } from "utils/api-client";
import { Notification } from "utils/notification";

function useGetMemberGamificationDetails() {
  const { memberDetails, setMemberDetails } = useGamificationContext();

  const { pathwayToken } = usePathwayTokenContext();

  const { vleToken } = useVleTokenContext();

  const getGamificationDataForMember = () => {
    return client({
      method: "get",
      endpoint: `api/learning-pathway/member?token=${pathwayToken}`,
      optional: {
        token: vleToken,
      },
    });
  };

  const { data, error, isLoading, isSuccess, isError, refetch } = useQuery(
    "gamificationDataForMember",
    getGamificationDataForMember,
    {
      select(data) {
        return data?.data?.data;
      },
      enabled: !!pathwayToken,
    }
  );

  useEffect(() => {
    if (isSuccess) {
      const badgesWithConvertOptions = data?.badges?.map((badge: any) => {
        let optionsToBeConverted: any = [];
        let optionsAfterConversion = [];
        if (badge.canClaim) {
          if (badge.pointCriteria && badge.pointCriteria.canClaim) {
            optionsToBeConverted.push({
              label: `${badge.pointCriteria.pointPerBadge} points`,
              value: {
                type: "REPUTATION_POINT",
                pointToBeSpend: badge.pointCriteria.pointPerBadge,
              },
            });
            optionsAfterConversion.push({
              label: `1 ${badge.title}`,
              value: {
                type: "REPUTATION_POINT",
                claimBadgeId: badge._id,
                claimBadgeCount: 1,
              },
            });
          }

          if (badge.badgesCriteria.length > 0) {
            badge.badgesCriteria.map((item: any) => {
              if (item.canClaim) {
                optionsToBeConverted.push({
                  label: `${item.quantityPerBadge} ${item.title}`,
                  value: {
                    type: "BADGE",
                    pointToBeSpend: item.quantityPerBadge,
                    badgeId: item._id,
                  },
                });
                optionsAfterConversion.push({
                  label: `1 ${badge.title}`,
                  value: {
                    type: "BADGE",
                    claimBadgeId: badge._id,
                    claimBadgeCount: 1,
                  },
                });
              }
            });
          }
          if (badge.eventCriteria) {
            optionsToBeConverted.push({
              label: badge?.eventCriteria?.eventName,
              value: {
                type: "EVENT",
              },
            });
            optionsAfterConversion.push({
              label: `${badge?.eventCriteria?.badgeQuantity} ${badge.title}`,
              value: {
                type: "EVENT",
                claimBadgeId: badge._id,
                claimBadgeCount: badge?.eventCriteria?.badgeQuantity,
              },
            });
          }
        }
        return {
          optionsToBeConverted,
          optionsAfterConversion,
          ...badge,
        };
      });

      setMemberDetails({
        allBadges: badgesWithConvertOptions,
        badges: data?.member?.badges?.metadata,
        reputationPoints: data?.member?.reputationPoint?.metadata,
        totalPoint: data?.member?.reputationPoint?.availablePoint,
        achievedBadges: data?.member?.badges?.achievedBadges,
        totalActivities: data?.totalActivities,
        totalAchievedBadges: data?.member?.badges?.totalAchieved,
      });
    }
  }, [data, isSuccess, setMemberDetails]);

  useEffect(() => {
    if (isError) {
      Notification({
        message: "Unable to get gamification data, please try again later",
        type: "warning",
        title: "Warning",
      });
    }
  }, [error, isError]);

  return {
    memberDetails,
    isLoading: isLoading,
    refetch,
  };
}

export { useGetMemberGamificationDetails };
