import { useState, useEffect } from "react";

import { client } from "utils/api-client";

import { useQuery } from "react-query";

import { Notification } from "utils/notification";

import { useVleTokenContext, useActiveMembersFilterContext } from "context";

function useGetLearnerAnalytics() {
	const [totalMembers, setTotalMembers] = useState<[]>([]);
	const [totalMembersCount, setTotalMembersCount] = useState<number>(0);

	const { filterBy } = useActiveMembersFilterContext();

	const [currentData, setCurrentData] = useState<any>([]);
	const [previousData, setPreviousData] = useState<any>([]);

	const [currentRange, setCurrentRange] = useState("");
	const [previousRange, setPreviousRange] = useState("");

	const { vleToken } = useVleTokenContext();

	const getLearnerAnalytics = ({
		filterBy,
	}: {
		filterBy: "week" | "month" | "year" | "";
	}) => {
		if (filterBy) {
			return client({
				method: "get",
				endpoint: "api/akademy/admin-learner-analytics",

				optional: {
					token: vleToken,
					params: {
						filter: filterBy,
					},
				},
			});
		} else {
			return null;
		}
	};

	const { data, error, isSuccess, isLoading, isError } = useQuery(
		["learnerAnalytics", { filterBy }],
		() => getLearnerAnalytics({ filterBy }),
		{
			refetchOnWindowFocus: false,
		}
	);

	useEffect(() => {
		if (isSuccess) {
			if (data) {
				const learnerAnalyticsData = data?.data?.data;

				setCurrentData(learnerAnalyticsData?.graphData?.current);
				setPreviousData(learnerAnalyticsData?.graphData?.previous);

				let learnerAnalytics;
				let currentRange;
				let previousRange;

				if (filterBy === "week") {
					learnerAnalytics = learnerAnalyticsData.this_week;
					currentRange = learnerAnalyticsData.this_week.range;
					previousRange = learnerAnalyticsData.previous_week.range;
				} else if (filterBy === "month") {
					learnerAnalytics = learnerAnalyticsData.this_month;
					currentRange = learnerAnalyticsData.this_month.range;
					previousRange = learnerAnalyticsData.previous_month.range;
				} else if (filterBy === "year") {
					learnerAnalytics = learnerAnalyticsData.this_year;
					currentRange = learnerAnalyticsData.this_year.range;
					previousRange = learnerAnalyticsData.previous_year.range;
				}

				setCurrentRange(currentRange);
				setPreviousRange(previousRange);

				setTotalMembers(learnerAnalytics.list);
				setTotalMembersCount(parseInt(learnerAnalytics.total));
			}
		}
	}, [data, filterBy, isSuccess]);

	useEffect(() => {
		if (isError) {
			Notification({
				message:
					"Unable to get the learner analytics data, please try again later",
				type: "warning",
				title: "Warning",
			});
		}
	}, [error, isError]);

	return {
		currentData,
		currentRange,
		totalMembers,
		previousData,
		previousRange,
		totalMembersCount,
		isLoadingLearnerAnalytics: isLoading,
	};
}

export { useGetLearnerAnalytics };
