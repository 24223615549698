/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";

import React, { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import DropArrow from "assets/images/common/drop-arrow.svg";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FormFloating } from "styles/form.styles";

const ReactFlagStyle = css`
  .ReactFlagsSelect-module_selectBtn__19wW7 {
    border: 1px solid #bcc3d1;
    border-radius: 6px;
    background-color: #fff;
    font-size: 12px !important;
    font-weight: 600;
    color: #13171f;
    height: calc(3rem + 1px);
  }

  .ReactFlagsSelect-module_selectValue__152eS {
    color: #9b9ba2;
    padding-left: 0;
  }

  .ReactFlagsSelect-module_label__27pw9 {
    color: #13171f;
    font-size: 12px;
  }

  .ReactFlagsSelect-module_selectBtn__19wW7:after {
    background-image: url(${DropArrow});
    background-repeat: no-repeat;
    border: none;
    width: 14px;
    height: 8px;
  }

  .ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded="true"]:after {
    transform: rotate(180deg);
  }

  .ReactFlagsSelect-module_selectOptions__3LNBJ {
    border-radius: 6px;
    border-color: #bcc3d1;
  }

  .ReactFlagsSelect-module_filterBox__3m8EU input {
    border: 1px solid #bcc3d1;
    border-radius: 6px;
    font-size: 12px;
  }

  .ReactFlagsSelect-module_flagsSelect__2pfa2 {
    padding-bottom: 0;
  }
`;

const LabelHide = css`
  .vle-form-floating {
    label {
      opacity: 0;
    }
    &.active {
      label {
        opacity: 1;
        color: #4e545f;
        font-size: 10px;
        transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
      }
      .ReactFlagsSelect-module_selectValue__152eS {
        margin: 11px 0 0 2px;
      }
    }
  }
`;

const CountrySelect = () => {
  const [selected, setSelected] = useState("");

  return (
    <FormFloating css={LabelHide}>
      <FloatingLabel
        controlId="floatingInput"
        label="Country"
        className={selected ? "active" : ""}
      >
        <ReactFlagsSelect
          searchable={true}
          placeholder="Country"
          searchPlaceholder="Search for a country"
          selected={selected}
          onSelect={(code) => setSelected(code)}
          css={ReactFlagStyle}
        />
      </FloatingLabel>
    </FormFloating>
  );
};

export default CountrySelect;
