import { useState, useEffect } from "react";

// Third party imports
import moment from "moment";
import { useInfiniteQuery } from "react-query";

// Util imports
import { client } from "utils/api-client";
import { Notification } from "utils/notification";
import formattedDateAndTime from "utils/formattedDateAndTime";

// Context imports
import { useActivityHistoryFilterContext, useVleTokenContext } from "context";
import { useAuthorizeLearnerPathway } from "hooks/useAuthorizeLearnerPathway";

function useGetActivityHistory() {
  const [activityHistory, setActivityHistory] = useState<any>(null);
  const [pageLimit, setPageLimit] = useState(10);

  const { vleToken } = useVleTokenContext();

  const { activity, dateRange, rewardType, search } =
    useActivityHistoryFilterContext();

  const [startDate, endDate] = dateRange as any;

  const { isLoading: isAuthorizing, pathwayToken } =
    useAuthorizeLearnerPathway();

  const endDateWithCurrentTime = new Date(endDate);
  endDateWithCurrentTime.setHours(23, 59, 59);

  const getActivityHistory = ({ pageParam = 1 }) => {
    let params: any = {
      pageNumber: pageParam,
      limit: pageLimit,
      token: pathwayToken,
      rewardType: rewardType?.value,
      activityId: activity?.value,
    };
    if (startDate) {
      params = {
        ...params,
        fromDate: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
      };
    }

    if (endDate) {
      params = {
        ...params,

        toDate: moment(endDateWithCurrentTime).format("YYYY-MM-DD HH:mm:ss"),
      };
    }

    if (search) {
      params = { ...params, searchKey: search };
    }
    return client({
      method: "get",
      endpoint: "api/learning-pathway/member-activities",
      optional: {
        token: vleToken,
        params: {
          ...params,
        },
      },
    });
  };

  const {
    data,
    error,
    isLoading,
    isSuccess,
    isError,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ["activityHistory", { activity, rewardType, dateRange, search }],
    getActivityHistory,
    {
      getNextPageParam: (_lastPage, pages) => {
        let totalData = pages[0]?.data?.data?.total;

        const totalPages = Math.ceil(totalData / pageLimit);

        if (pages.length < totalPages) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },

      refetchOnWindowFocus: false,

      enabled: !isAuthorizing && !!pathwayToken,
    }
  );

  useEffect(() => {
    if (isSuccess) {
      const allData: any = [];
      data?.pages?.map((group) => {
        group?.data?.data?.memberActivities?.map((item: any, index: any) => {
          allData.push({
            reward: item?.reward,
            activity: item?.activity,
            actionAt: formattedDateAndTime(item?.actionAt),
          });

          return allData;
        });

        return allData;
      });

      setActivityHistory(allData);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (isError) {
      Notification({
        message: "Unable to get activity history data, please try again later",
        type: "warning",
        title: "Warning",
      });
    }
  }, [error, isError]);

  return {
    activityHistory,
    isLoading,
    isSuccess,
    setPageLimit,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    pageLimit,
  };
}

export { useGetActivityHistory };
