import React, { useMemo } from "react";

import { localStorageKeys, useLocalStorageState } from "hooks";

interface MykademyDataContextParams {
  mykademyUserData: {
    akademy: {
      access_token: string;
      login_url: string;
      org_logo: string;
      user_data: {
        id: string;
        role_name: string;
        us_account_id: string;
        us_country: string;
        us_country_code: string;
        us_deleted: string;
        us_email: string;
        us_image: string;
        us_name: string;
        us_organization: string;
        us_phone: string;
        us_role_id: string;
        us_status: string;
        us_timezone: string;
        branch_code: string;
        branch_id: string;
        branch_name: string;
      };
    };
    onBoardingProcess: {
      step_one: number;
      step_two: number;
      step_three: number;
      step_four: number;
      step_five: number;
      step_six: number;
    };
    userData: {
      domain: string;
      email: string;
      isLegacy: boolean;
      mk_account_id: string;
      mk_member_id: string;
      mk_token: string;
      orgLogo: string;
      orgName: string;
      role_name: string;
      tenant_id?: string;
      is_sso_user: string;
      isFirstLogin: boolean;
      gamification: any;
    };
    vleToken: string;
  };
  setMykademyUserData: (state: any) => null;
  removeMykademyUserDataFromLocalStorage: () => null;
}

const MykademyDataContext = React.createContext<
  MykademyDataContextParams | undefined
>(undefined);

MykademyDataContext.displayName = "MykademyDataContext";

function MykademyDataProvider(props: any) {
  const [
    mykademyUserData,
    setMykademyUserData,
    removeMykademyUserDataFromLocalStorage,
  ] = useLocalStorageState({
    key: localStorageKeys.mykademyUserData,
  });

  const value = useMemo(() => {
    return {
      mykademyUserData,
      setMykademyUserData,
      removeMykademyUserDataFromLocalStorage,
    };
  }, [
    mykademyUserData,
    setMykademyUserData,
    removeMykademyUserDataFromLocalStorage,
  ]);

  return <MykademyDataContext.Provider value={value} {...props} />;
}

function useMykademyDataContext() {
  const context = React.useContext(MykademyDataContext);

  if (context === undefined) {
    throw new Error(
      `useMykademyDataContext must be used within a MykademyDataProvider`
    );
  }

  return {
    ...context,
    isMykademy:
      Object.keys(context.mykademyUserData).length !== 0 ? true : false,
  };
}

export { MykademyDataProvider, useMykademyDataContext };
