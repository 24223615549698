/** @jsxRuntime classic */
/** @jsx jsx */
import { css } from "@emotion/react";

// Third party imports
import styled from "@emotion/styled/macro";

// Image imports
import BgBlack from "assets/images/common/bg-header-01.png";
import BgImageOne from "assets/images/getStarted/landing-tick.png";

import * as mq from "styles/media-queries";

const flex = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
});

const getStarted = css({
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: `url(${BgImageOne}) no-repeat calc(50% + 40px) 100%/1120px auto, url(${BgBlack}) no-repeat 50% / cover`,
  padding: "40px 0",

  [mq.hdMinus]: {
    alignItems: "flex-start",
  },
});

const Heading = styled.h2`
  color: #fff !important;
  fontsize: 36px !important;
  marginbottom: 20px !important;
`;

const TellMeMoreWrapper = styled.div({
  display: "flex",
  padding: "15px",
  borderRadius: "4px",
  marginBottom: "15px",
  alignItems: "center",
  backgroundColor: "#fff",
  justifyContent: "center",
});

const NewAccountText = styled.span({
  display: "flex",
  fontSize: "14px",
  marginRight: "10px",
  alignItems: "center",
  justifyContent: "center",
});

const IconStar = styled.img({
  width: "22px",
  marginRight: "15px",
});

const GetStartedMessage = styled.div`
  color: #fff;
  font-weight: 600;
  margin-bottom: 30px;
  max-width: 530px;

  ${mq.smallPhone} {
    font-size: 14px;
    line-height: 14px;
  }

  ${mq.tablet} {
    font-size: 32px;
    line-height: 32px;
  }
`;

const FreeAccessText = styled.span`
  color: #00ffa6;
`;

const SignUpText = styled.div``;

const LineTitle = styled.div`
  min-width: 400px;
  margin: 30px 0;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-top: 1px solid #eaeaea;

  ${mq.maxTableWide} {
    min-width: 100%;
  }

  span {
    display: inline-block;
    vertical-align: baseline;
    zoom: 1;
    *display: inline;
    *vertical-align: auto;
    position: relative;
    padding: 0 20px;
    color: #eaeaea;

    // &:before,
    // &:after {
    // 	content: "";
    // 	display: block;
    // 	width: 1000px;
    // 	position: absolute;
    // 	top: 0.73em;
    // 	border-top: 1px solid #eaeaea;
    // }
    // &:before {
    // 	right: 100%;
    // }
    // &:after {
    // 	left: 100%;
    // }
  }
`;

const Ul = styled.ul`
  list-style: auto;
  padding-left: 0px;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
  }
`;

export {
  Ul,
  flex,
  Heading,
  IconStar,
  LineTitle,
  getStarted,
  SignUpText,
  NewAccountText,
  FreeAccessText,
  TellMeMoreWrapper,
  GetStartedMessage,
};
