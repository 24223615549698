// Third party imports
import { useMutation, useQueryClient } from "react-query";

// Util imports
import { client } from "utils/api-client";
import { Notification } from "utils/notification";

// Context imports
import { useVleTokenContext } from "context";
import showGamificationMessage from "utils/showGamificationMessage";

function useSubscribeBundle({ bundleId }: any) {
  const queryClient = useQueryClient();

  const { vleToken } = useVleTokenContext();

  const subscribeBundle = (data: any) => {
    let apiBody = {
      bundle_id: data["bundleId"],
    } as const;

    return client({
      method: "post",
      endpoint: "api/akademy/subscribe-bundle",
      optional: {
        data: apiBody,
        token: vleToken,
      },
    });
  };

  const { mutate, isLoading } = useMutation(subscribeBundle, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("bundleList");
      queryClient.removeQueries("bundleList");
      showGamificationMessage({ gamification: data?.data?.data?.gamification });
    },

    onError: (error: any) => {
      const errorMsg = error?.response.data.message;

      Notification({
        message: errorMsg
          ? errorMsg
          : "Unable to subscribe bundle, please try again later",
        type: "warning",
        title: "Warning",
      });
    },
  });

  return {
    mutate,
    isLoading,
  };
}

export { useSubscribeBundle };
