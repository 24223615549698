/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { Fragment } from "react";

import { ModalDismissButton } from "components";

import { useLinkSso, useIsMobileView } from "hooks";

import { Button, CustomHeightSpinner } from "styles/components.styles";

import {
  Text,
  Label,
  Title,
  Image,
  Header,
  Content,
  SubHeader,
} from "components/profileInformation/profileInformation.styles";

import defaultUserImage from "assets/images/customAssets/default_user.jpg";

import PwIcon from "assets/images/getStarted/password-icon.svg";

import { useMykademyDataContext, useUserRoleContext } from "context";

const ShowProfileInfo = ({
  routeToPage,
  profileInfo,
  showSsoUpdateButton,
}: any) => {
  const { firstName, lastName, email, imageUrl, domain, organizationName } =
    profileInfo;

  const updateRoleUrl =
    process.env.REACT_APP_TEAMS_ADMIN_SSO_UPDATE_ROLE ||
    "devteams-admin-sso-update-role";

  const { setLinkSso, updateRoleLoader } = useLinkSso({ url: updateRoleUrl });

  const { isSmallTabletView } = useIsMobileView();

  const { mykademyUserData } = useMykademyDataContext();

  const { isLearner } = useUserRoleContext();

  const isSsoUser = mykademyUserData.userData.is_sso_user;

  return (
    <Fragment>
      {updateRoleLoader ? (
        <CustomHeightSpinner height="591px" />
      ) : (
        <Fragment>
          <Header css={{ marginTop: "10px" }}>
            <Image
              src={
                imageUrl
                  ? "https://" + imageUrl.split("https://").slice(-1)[0]
                  : defaultUserImage
              }
              alt="profile image"
            />
            <Title>Profile</Title>
            {showSsoUpdateButton &&
            isSsoUser &&
            isLearner &&
            !isSmallTabletView ? (
              <Button
                variant="microsoftBg"
                css={{ marginLeft: "auto" }}
                onClick={() => setLinkSso(true)}
                title="Click if you are either teams administrator or user administrator or global administrator"
              >
                Upgrade Role
              </Button>
            ) : null}
          </Header>

          <SubHeader>
            <Text>Personal</Text>
            {localStorage.getItem("tenant_id") ? null : (
              <Button
                variant="purpleText"
                padding="0"
                onClick={() => routeToPage("editProfileInfo")}
              >
                Update
              </Button>
            )}
          </SubHeader>

          <Label>First Name</Label>
          <Content>{firstName}</Content>

          <Label>Last Name</Label>
          <Content>{lastName}</Content>

          <Label>Email</Label>
          <Content>{email}</Content>

          <Label>Organisation</Label>
          <Content>{organizationName}</Content>

          <Label>Domain</Label>
          <Content>{domain}</Content>

          <SubHeader>
            <Text>Security</Text>
            {localStorage.getItem("tenant_id") ? null : (
              <Button
                variant="purpleText"
                padding="0"
                onClick={() => routeToPage("editPassword")}
              >
                Update
              </Button>
            )}
          </SubHeader>

          <Label>Password</Label>
          <Content>
            <img src={PwIcon} alt="pw icon" />
          </Content>

          <ModalDismissButton>
            <Button
              variant="dark"
              fontweight="600"
              css={{ marginLeft: "auto" }}
            >
              Close
            </Button>
          </ModalDismissButton>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ShowProfileInfo;
