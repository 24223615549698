/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { Fragment } from "react";

import MenuItems from "components/navbar/menuItems";

import { useIsMobileView } from "hooks";

import { DropdownUl } from "components/navbar/navbar.styles";

interface DropdownItemProps {
  title: string;
  route?: string | undefined;
  icon: any;
}

interface DropdownProps {
  submenus: DropdownItemProps[];
  dropdown: boolean;
  showSsoButton: any;
  depthLevel: number;
  setType: React.Dispatch<React.SetStateAction<string>>;
}

const NavbarDropdown = ({
  setType,
  submenus,
  dropdown,
  depthLevel,
  showSsoButton,
}: DropdownProps) => {
  const { isSmallTabletView } = useIsMobileView();

  depthLevel = depthLevel + 1;

  return (
    <Fragment>
      <DropdownUl
        dropdown={dropdown}
        depthLevel={depthLevel}
        isSmallTabletView={isSmallTabletView}
      >
        {submenus.map((submenu: any) => {
          return (
            <Fragment key={submenu?.title}>
              <MenuItems
                setType={setType}
                key={submenu?.title}
                item={submenu}
                depthLevel={depthLevel}
                showSsoButton={showSsoButton}
              />
            </Fragment>
          );
        })}
      </DropdownUl>
    </Fragment>
  );
};

export default NavbarDropdown;
