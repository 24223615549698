import React from "react";

//Component imports
import { PointCalculationSuccessBox } from "components";
import { Notification } from "./notification";

const showGamificationMessage = ({ gamification }: any) => {
  if (gamification) {
    let sentence = "";
    const reputationPointsData = gamification?.filter(
      (item: any) => !!item?.data?.scoreAdded
    );
    const badgeData = gamification?.filter(
      (item: any) => !!item?.data?.achieved
    );
    const totalReputationPoints = reputationPointsData?.reduce(
      (accumulator: any, currentValue: any) =>
        accumulator + currentValue?.data?.scoreAdded,
      0
    );
    if (totalReputationPoints) {
      sentence = totalReputationPoints + " reputation points";
    }

    if (badgeData?.length > 0) {
      let badgeCounts: any = [];

      badgeData?.forEach((badge: any) => {
        return badge?.data?.achieved.forEach((item: any) => {
          const existingBadge = badgeCounts.find((countItem: any) =>
            countItem.includes(item.title)
          );
          if (existingBadge) {
            const count = parseInt(existingBadge.split(" ")[0]); // Extract the current count
            const newCount = count + item.count; // Increase the count
            const updatedBadge = `${newCount} ${item.title}`;
            badgeCounts = badgeCounts.map((countItem: any) =>
              countItem.includes(item.title) ? updatedBadge : countItem
            );
          } else {
            badgeCounts.push(`${item.count} ${item.title}`);
          }
        });
      });

      const lastBadge = badgeCounts.pop();

      if (badgeCounts.length === 0) {
        if (sentence !== "") {
          sentence += `, and ${lastBadge} badge`;
        } else {
          sentence += ` ${lastBadge} badge`;
        }
      } else {
        sentence += `, ${badgeCounts.join(", ")}, and ${lastBadge} badges`;
      }
    }

    if (sentence !== "") {
      const message = `Congratulations ${
        reputationPointsData[0]?.data?.member?.name ??
        badgeData[0]?.data?.member?.name
      }, you've earned ${sentence}`;

      const oldMessage = localStorage.getItem("achievementMessage");

      if (oldMessage) {
        localStorage.removeItem("achievementMessage");
        localStorage.setItem("achievementMessage", message);
      } else {
        localStorage.setItem("achievementMessage", message);
      }

      Notification({
        content: <PointCalculationSuccessBox message={message} />,
        dismiss: {
          duration: 5000,
        },
      });

      return message;
    }
  }
};

export default showGamificationMessage;
