import { localStorageKeys, useLocalStorageState } from "hooks";
import React, { useState, useMemo, useEffect } from "react";

interface IPowerBiContextParams {
  powerBiToken: string;
  setPowerBiToken: React.Dispatch<React.SetStateAction<any>>;
  removePowerBiTokenFromLocalStorage: () => null;
  selectedWorkspace: any;
  setSelectedWorkspace: React.Dispatch<React.SetStateAction<any>>;
  selectedType: any;
  setSelectedType: React.Dispatch<React.SetStateAction<any>>;
  selectedItemToBeViewed: any;
  setSelectedItemToBeViewed: React.Dispatch<React.SetStateAction<any>>;
}

const PowerBiContext = React.createContext<IPowerBiContextParams | undefined>(
  undefined
);

PowerBiContext.displayName = "PowerBiContext";

function PowerBiProvider(props: any) {
  const [selectedWorkspace, setSelectedWorkspace] = useState<any>(null);
  const [selectedType, setSelectedType] = useState<any>(null);
  const [selectedItemToBeViewed, setSelectedItemToBeViewed] =
    useState<any>(null);
  const [powerBiToken, setPowerBiToken] = useState("");

  useEffect(() => {
    const storedToken = localStorage.getItem("powerBiToken");
    if (storedToken) {
      setPowerBiToken(storedToken);
    }
  }, []);

  const value = useMemo(() => {
    const savePowerBiTokenLocalStorageToken = (token: string) => {
      localStorage.setItem("powerBiToken", token);
      setPowerBiToken(token);
    };

    const removePowerBiTokenFromLocalStorage = () => {
      localStorage.removeItem("powerBiToken");
      setPowerBiToken("");
    };
    return {
      selectedType,
      powerBiToken,
      setSelectedType,
      setPowerBiToken,
      selectedWorkspace,
      setSelectedWorkspace,
      selectedItemToBeViewed,
      setSelectedItemToBeViewed,
      savePowerBiTokenLocalStorageToken,
      removePowerBiTokenFromLocalStorage,
    };
  }, [
    powerBiToken,
    selectedType,
    setPowerBiToken,
    selectedWorkspace,
    selectedItemToBeViewed,
  ]);

  return <PowerBiContext.Provider value={value} {...props} />;
}

function usePowerBiContext() {
  const context = React.useContext(PowerBiContext);

  if (context === undefined) {
    throw new Error(`usePowerBiContext must be used within a PowerBiProvider`);
  }

  return context;
}

export { PowerBiProvider, usePowerBiContext };
