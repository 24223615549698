import React, { useRef, useEffect, useState } from "react";

const useGetNavbarHeight = () => {
  const [navbarHeight, setNavbarHeight] = useState("");
  const navbarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (navbarRef.current) {
        const height = navbarRef.current.offsetHeight;
        setNavbarHeight(height + "px");
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return {
    navbarHeight,
    navbarRef,
  };
};

export default useGetNavbarHeight;
