import { MenuItem, Select } from "@mui/material";
import { AxiosResponse } from "axios";

import React, { FC, useMemo, Dispatch, SetStateAction } from "react";

import { FetchNextPageOptions, InfiniteQueryObserverResult } from "react-query";
import { Button, CenteredSpinner } from "styles/components.styles";
import { PageLimitSetterWrapper } from "./pageLimitSetter.styles";

interface IPageLimitSetterProps {
  pageLimit: number;
  setPageLimit: Dispatch<SetStateAction<number>>;
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean;
  totalCount: string;
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<AxiosResponse<any>, unknown>>;
}

const PageLimitSetter: FC<IPageLimitSetterProps> = ({
  pageLimit,
  setPageLimit,
  // fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  totalCount,
}) => {
  function showLoadMoreButton() {
    if (hasNextPage) {
      if (isFetchingNextPage) {
        return <CenteredSpinner />;
      } else {
        return (
          <Button
            variant="transparent"
            css={{ margin: "0 auto" }}
            onClick={() => {
              // fetchNextPage();
              setPageLimit((prevLimit) => prevLimit + 10);
            }}
          >
            Load More
          </Button>
        );
      }
    } else {
      return null;
    }
  }

  const arrayOfPages = useMemo(() => {
    const newArr: any = [20];
    let start = 40;
    while (start <= parseInt(totalCount) + 20) {
      newArr.push(start);
      start += 20;
    }
    return newArr;
  }, [totalCount]);

  return (
    <PageLimitSetterWrapper>
      <div
        style={{
          visibility: "hidden",
        }}
      >
        Hello
      </div>
      {showLoadMoreButton()}
      <div>
        Rows per page:
        <Select
          defaultValue={pageLimit}
          variant="standard"
          disableUnderline={true}
          sx={{
            marginLeft: "10px",
          }}
          onChange={(e) => {
            setPageLimit(e.target.value as number);
          }}
        >
          {arrayOfPages?.map((item: any) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </div>
    </PageLimitSetterWrapper>
  );
};

export default PageLimitSetter;
