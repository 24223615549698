import styled from "@emotion/styled/macro";
import { BoxShadow, Button } from "styles/components.styles";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

const RedeemBoxWrapper = styled(BoxShadow)`
  /* Add any additional styles */
`;

const RedeemIcon = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  margin-top: 1.3rem;
  img {
    width: 120px;
    height: 100px;
  }
`;

const RedeemTitle = styled.h3`
  /* Add your redeem title styles */
`;

const RedeemDescription = styled.div`
  text-align: center;
  margin-top: 1.3rem;
  font-size: 1rem;
`;

const ConvertLabel = styled.strong`
  /* Add your convert label styles */
`;

const ConvertBox = styled(Box)`
  width: 100%;
  margin-top: 1.3rem;
`;

const ConvertFormControl = styled(FormControl)`
  /* Add your convert form control styles */
`;

const ConvertInputLabel = styled(InputLabel)`
  /* Add your convert input label styles */
`;

const ConvertSelect = styled(Select)`
  /* Add your convert select styles */
`;

const ArrowIconContainer = styled.div`
  background: #d9d9d9;
  width: 60px;
  height: 30px;
  border-radius: 50%;
  display: grid;
  place-items: center;
`;

const YouGetFormControl = styled(FormControl)`
  /* Add your you get form control styles */
`;

const YouGetInputLabel = styled(InputLabel)`
  /* Add your you get input label styles */
`;

const YouGetSelect = styled(Select)`
  /* Add your you get select styles */
`;

const TotalBadgeText = styled.div`
  /* Add your total badge text styles */
`;

const RedeemButton = styled(Button)`
  /* Add your redeem button styles */
`;

export {
  RedeemBoxWrapper,
  RedeemButton,
  RedeemDescription,
  RedeemIcon,
  RedeemTitle,
  ConvertBox,
  ArrowIconContainer,
};
