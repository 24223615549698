// Third party imports
import styled from "@emotion/styled/macro";

const Tr = styled.tr``;

const Th = styled.th`
  border: none;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 15px;
  text-align: center;
`;

const Td = styled.td`
  padding: 12px 8px !important;
  border-color: #f0f0f0 !important;
  text-align: center !important;
  white-space: nowrap;
`;

const Thead = styled.thead``;

const Tbody = styled.tbody``;

const NameWrapper = styled.div`
  text-align: left !important;
  display: flex !important;
  align-items: center !important;
`;

export { Tr, Th, Td, Tbody, Thead, NameWrapper };
