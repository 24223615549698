import React, { FC, Fragment, useEffect, useState } from "react";
import { ManualClaimHeader } from "./manualClaim.styles";
import RedeemIconImage from "assets/images/gamification/redeemIcon.png";
import { Button } from "styles/components.styles";

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { SlArrowRight } from "react-icons/sl";
import {
  ArrowIconContainer,
  ConvertBox,
  RedeemDescription,
  RedeemIcon,
} from "./redeemBox.styles";
import {
  useClaimBadgeFromEvent,
  useIsMobileView,
  useManuallyClaimBadge,
} from "hooks";
import PointCalculationSuccessBox from "components/pointCalculationSuccessBox/pointCalculationSuccessBox";
import { Spinner } from "react-bootstrap";
import { Notification } from "utils/notification";

interface IRedeemBoxProps {
  setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsClaimButtonClicked: React.Dispatch<React.SetStateAction<boolean>>;
  optionsToBeConverted: any;
  optionsAfterConversion: any;
  memberDetails: any;
}

const showMessageAfterClaim = (res: any) => {
  const message = `You've earned ${res?.data?.data?.achieved[0]?.count} ${res?.data?.data?.achieved[0]?.title} badge`;
  const oldMessage = localStorage.getItem("achievementMessage");
  if (oldMessage) {
    localStorage.removeItem("achievementMessage");
    localStorage.setItem("achievementMessage", message);
  } else {
    localStorage.setItem("achievementMessage", message);
  }

  Notification({
    content: <PointCalculationSuccessBox message={message} />,
    dismiss: {
      duration: 5000,
    },
  });
};

const RedeemBox: FC<IRedeemBoxProps> = ({
  setIsPopoverOpen,
  setIsClaimButtonClicked,
  optionsAfterConversion,
  optionsToBeConverted,
  memberDetails,
}) => {
  const [selectedOptionForConversion, setSelectedOptionForConversion] =
    useState<any>(optionsToBeConverted[0]);
  const [selectedOptionAfterConversion, setSelectedOptionAfterConversion] =
    useState<any>(optionsAfterConversion[0]);

  const [eventCode, setEventCode] = useState("");

  const { isLoading, mutateAsync } = useManuallyClaimBadge();

  const {
    isLoading: isClaimingBadgeFromEvent,
    mutateAsync: claimBadgeFromEvent,
  } = useClaimBadgeFromEvent();

  const handleChangeForConversion = (event: any) => {
    setSelectedOptionForConversion(event.target.value);
  };

  const handleChangeAfterConversion = (event: any) => {
    setSelectedOptionAfterConversion(event.target.value);
  };

  useEffect(() => {
    if (optionsAfterConversion) {
      const selectedItem = optionsAfterConversion.find(
        (item: any) =>
          item.value.type === selectedOptionForConversion?.value?.type
      );

      setSelectedOptionAfterConversion(selectedItem);
    }
  }, [optionsAfterConversion, selectedOptionForConversion?.value?.type]);

  const handleRedeem = () => {
    if (selectedOptionAfterConversion && selectedOptionForConversion) {
      if (selectedOptionForConversion?.value?.type === "EVENT" && eventCode) {
        claimBadgeFromEvent({
          claimBadgeId: selectedOptionAfterConversion?.value?.claimBadgeId,
          eventCode,
        }).then((res) => {
          showMessageAfterClaim(res);
          setIsPopoverOpen(false);
        });
      } else {
        mutateAsync({
          claimBadgeId: selectedOptionAfterConversion?.value?.claimBadgeId,
          claimBadgeCount:
            selectedOptionAfterConversion?.value?.claimBadgeCount,
          spentType: selectedOptionForConversion?.value?.type,
          spentCount: selectedOptionForConversion?.value?.pointToBeSpend,
          spentBadgeId: selectedOptionForConversion?.value?.badgeId,
        }).then((res) => {
          showMessageAfterClaim(res);
          setIsPopoverOpen(false);
        });
      }
    }
  };

  const { isMobileView } = useIsMobileView();

  return (
    <div
      style={{
        width: isMobileView ? "80vw" : "30rem",
        padding: "20px",
      }}
    >
      <ManualClaimHeader
        style={{
          justifyContent: "flex-end",
        }}
      >
        <span
          style={{
            cursor: "pointer",
          }}
          onClick={() => setIsClaimButtonClicked(false)}
        >
          Close
        </span>
      </ManualClaimHeader>

      <RedeemIcon
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: "1.2rem",
          marginTop: "1.3rem",
        }}
      >
        <img
          src={RedeemIconImage}
          alt="redeem icon"
          style={{
            height: "100px",
            width: "100px",
          }}
        />
      </RedeemIcon>
      <RedeemDescription>
        <h3>{memberDetails?.totalPoint}</h3>
        <div>Available R-Points</div>
        <div>Convert your R-points to badge</div>
        <h3>
          {selectedOptionForConversion?.label} ={" "}
          {selectedOptionAfterConversion?.label} Badge
        </h3>
      </RedeemDescription>
      <ConvertBox>
        {selectedOptionForConversion?.value?.type === "EVENT" ? (
          <TextField
            required
            id="outlined-size-normal"
            fullWidth
            defaultValue={eventCode}
            variant="standard"
            label="Enter event code"
            onChange={(e) => setEventCode(e.target.value)}
          />
        ) : (
          <Fragment>
            <strong>Select Below:</strong>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #D3D3D3",
                padding: "2px 5px",
                borderRadius: "6px",
                gap: "0.3rem",
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Convert</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedOptionForConversion}
                  label="Convert"
                  onChange={handleChangeForConversion}
                  disableUnderline
                  renderValue={(value: any) => {
                    return value?.label;
                  }}
                >
                  {optionsToBeConverted?.map((item: any, index: any) => (
                    <MenuItem key={index} value={item}>
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <ArrowIconContainer>
                <SlArrowRight
                  style={{
                    height: "15px",
                    width: "15px",
                    color: "#444791",
                  }}
                />
              </ArrowIconContainer>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  You'll get
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedOptionAfterConversion}
                  label="You'll get"
                  onChange={handleChangeAfterConversion}
                  disableUnderline
                  renderValue={(value: any) => {
                    return value?.label;
                  }}
                >
                  <MenuItem value={selectedOptionAfterConversion}>
                    {selectedOptionAfterConversion?.label}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Fragment>
        )}
      </ConvertBox>
      <div
        style={{
          textAlign: "center",
          marginTop: "1.3rem",
        }}
      >
        You will have total {selectedOptionAfterConversion?.label} Badge
      </div>
      <Button
        variant="purple"
        width="100%"
        onClick={handleRedeem}
        disabled={
          isLoading ||
          isClaimingBadgeFromEvent ||
          selectedOptionForConversion?.value?.type === "EVENT"
            ? eventCode === ""
            : false
        }
        style={{
          marginTop: "1.5rem",
        }}
      >
        {isLoading || isClaimingBadgeFromEvent ? (
          <span
            style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
          >
            <Spinner size="sm" />
            Redeem Now
          </span>
        ) : (
          "Redeem Now"
        )}
      </Button>
    </div>
  );
};

export default RedeemBox;
