/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { Fragment, useState } from "react";

// Third party imports
import styled from "@emotion/styled/macro";
import { Row, Col } from "react-bootstrap";
import { GiHamburgerMenu } from "react-icons/gi";

// Component imports
import { Navbar } from "components";

// Hook imports
import { useIsMobileView, useGetOrgnameSetting } from "hooks";
import { useCreateProfileInfo } from "components/profileInformation/useCreateProfileInfo";

// Style imports
import { Button } from "styles/components.styles";

// Asset imports
import defaultUserImage from "assets/images/customAssets/default_user.jpg";

const HeaderWrap = styled.div`
  background: #f5f5f5;
  padding: 15px 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
  border-radius: 0;
  border-radius: 4px;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 12%), 0 2px 4px 0 rgb(0 0 0 / 14%);
  border: solid 0 #e73550;
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 20px;
`;

interface OrgImgProps {
  isLoading: boolean;
  isTabletView: boolean;
  showOrgNameBesideLogo: boolean;
}

const OrganizationImg = styled.img<OrgImgProps>`
  // width: 100px;
  height: 60px;
  max-width: 100%;
  border-right: ${({ isTabletView, showOrgNameBesideLogo, isLoading }) =>
    isTabletView || !showOrgNameBesideLogo || isLoading
      ? ""
      : "2px solid #dae0e5"};
  padding-right: 15px;
`;

const OrganizationName = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-left: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // width: 220px;
`;

const OrgWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: -15px;
`;

interface MobileNavWrapperProps {
  showNavigation: boolean;
  isSmallTabletView: boolean;
}

const MobileNavWrapper = styled.div<MobileNavWrapperProps>`
  position: absolute;
  top: 90px;
  left: 0;
  background: #f5f5f5;
  width: 100%;
  display: ${({ showNavigation, isSmallTabletView }) =>
    showNavigation && isSmallTabletView ? "flex" : "none"};
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 30px;
`;

const Header = ({
  hasPendingBill,
  showSsoButton,
  showSsoUpdateButton,
}: any) => {
  const [showNavigation, setShowNavigation] = useState(false);
  const {
    profileInfo: { imageUrl, organizationLogo, organizationName },
  } = useCreateProfileInfo();

  const { isTabletView, isSmallTabletView } = useIsMobileView();

  const { isLoading, showOrgNameBesideLogo } = useGetOrgnameSetting();

  return (
    <HeaderWrap>
      {/* {showNavigation && isSmallTabletView ? ( */}
      <MobileNavWrapper
        showNavigation={showNavigation}
        isSmallTabletView={isSmallTabletView}
      >
        <Navbar
          hasPendingBill={hasPendingBill}
          showSsoUpdateButton={showSsoUpdateButton}
        />
        <ProfileImage
          src={
            imageUrl
              ? "https://" + imageUrl.split("https://").slice(-1)[0]
              : defaultUserImage
          }
          alt="profile pic"
          css={{
            marginTop: isSmallTabletView ? "10px" : "",
          }}
        />
      </MobileNavWrapper>
      {/* ) : null} */}
      <Row>
        <Col xs={4} sm={4} md={4}>
          <OrgWrapper>
            <OrganizationImg
              src={organizationLogo}
              alt="organization image"
              isLoading={isLoading}
              isTabletView={isTabletView}
              showOrgNameBesideLogo={showOrgNameBesideLogo}
            />
            {isTabletView || !showOrgNameBesideLogo || isLoading ? null : (
              <OrganizationName title={organizationName}>
                {organizationName}
              </OrganizationName>
            )}
          </OrgWrapper>
        </Col>
        <Col
          xs={8}
          sm={8}
          md={8}
          css={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {isSmallTabletView ? (
            <Button
              variant="transparent"
              onClick={() => setShowNavigation((prev) => !prev)}
            >
              <GiHamburgerMenu css={{ width: "20px", height: "20px" }} />
            </Button>
          ) : (
            <Fragment>
              <Navbar
                showSsoButton={showSsoButton}
                hasPendingBill={hasPendingBill}
                showSsoUpdateButton={showSsoUpdateButton}
              />
              <ProfileImage
                src={
                  imageUrl
                    ? "https://" + imageUrl.split("https://").slice(-1)[0]
                    : defaultUserImage
                }
                alt="profile pic"
                css={{
                  marginTop: isSmallTabletView ? "10px" : "",
                }}
              />
            </Fragment>
          )}
        </Col>
      </Row>
    </HeaderWrap>
  );
};

export default Header;
