import { useState, useEffect, useCallback } from "react";

// Third party imports
import axios from "axios";
import * as microsoftTeams from "@microsoft/teams-js";

// Hook imports
import {
	useAsync,
	localStorageKeys,
	useDeeplinkRouting,
	useLocalStorageState,
} from "hooks";

// Component imports
import { useHistory } from "react-router-dom";
import { Notification } from "utils/notification";

import { useModalContext } from "components/modal/modal";

import {
	useDomainContext,
	useVleTokenContext,
	useUserRoleContext,
	useMykademyDataContext,
} from "context";

import { client } from "utils/api-client";
import { vleConfig } from "utils/vleConfig";

function useGetTokenFromMS() {
	const baseURL = process.env.REACT_APP_TEAMS_API_URL;
	const teamsLoginAuthUrl = process.env.REACT_APP_TEAMS_LOGIN_AUTH;

	const [hide, setHide] = useState(false);
	const [hideButtons, setHideButtons] = useState(false);

	const [ssoRegister, setSsoRegister] = useState(false);
	const [allDataSet, setAllDataSet] = useState(false);
	const [checkAutoLogin, setCheckAutoLogin] = useState(false);

	const [showLoginPage, setShowLoginPage] = useLocalStorageState({
		key: localStorageKeys.showLoginPage,
	});

	const { deeplinkParams, setDeeplinkParams } = useDeeplinkRouting();

	const { run, data, error, isSuccess, isError } = useAsync();

	const history = useHistory();

	const { setIsOpen } = useModalContext();
	const { setDomain } = useDomainContext();

	const { vleToken, setVleToken } = useVleTokenContext();
	const { userRole, setUserRole } = useUserRoleContext();
	const { mykademyUserData, setMykademyUserData } = useMykademyDataContext();

	const ssoAuth = useCallback(
		async (context: microsoftTeams.Context) => {
			const ssoLoginSuccess = async (
				result: any,
				isMultipleAccountSso?: boolean
			) => {
				if (context.tid) {
					localStorage.setItem("tenant_id", context.tid);
				}

				const apiBody = isMultipleAccountSso
					? {
							tenant_id: context.tid,
							access_token: result.access_token,
					  }
					: {
							tenant_id: context.tid,
							access_token: result,
							convertToken: true,
					  };

				run(
					client({
						method: "post",
						endpoint: "api/akademy/authenticate-via-ms-teams",
						optional: {
							data: apiBody,
						},
					})
				);
			};

			const ssoLoginFailure = async (error: any) => {
				console.error("SSO failed: ", error);

				if (error.toLowerCase() === "failedtoopenwindow") {
					setHide(true);

					setIsOpen(true);
				}
			};

			// function openSsoPopup() {
			// 	// Perform Azure AD single sign-on authentication
			// 	let authTokenRequestOptionsFailure = {
			// 		url: `/${teamsLoginAuthUrl}`,
			// 		width: 500,
			// 		height: 504,
			// 		successCallback: (result: any) => {

			// 			const isMultipleAccountSso = true;

			// 			ssoLoginSuccess(result, isMultipleAccountSso);
			// 		}, //The result variable is the SSO token.
			// 		failureCallback: (error: any) => {
			// 			ssoLoginFailure(error);
			// 		},
			// 	};

			// 	microsoftTeams.authentication.authenticate(
			// 		authTokenRequestOptionsFailure
			// 	);
			// }

			// Perform Azure AD single sign-on authentication
			let authTokenRequestOptions = {
				successCallback: (result: any) => {
					ssoLoginSuccess(result);
				}, //The result variable is the SSO token.
				failureCallback: (error: any) => {
					// openSsoPopup();

					// Perform Azure AD single sign-on authentication
					let authTokenRequestOptionsFailure = {
						url: `/${teamsLoginAuthUrl}`,
						width: 500,
						height: 504,
						successCallback: (result: any) => {
							const isMultipleAccountSso = true;

							ssoLoginSuccess(result, isMultipleAccountSso);
						}, //The result variable is the SSO token.
						failureCallback: (error: any) => {
							ssoLoginFailure(error);
						},
					};

					microsoftTeams.authentication.authenticate(
						authTokenRequestOptionsFailure
					);

					// ssoLoginFailure(error);
				},
			};

			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const response = microsoftTeams.authentication.getAuthToken(
				authTokenRequestOptions
			);

			// return openSsoPopup;
		},
		[run, setIsOpen, teamsLoginAuthUrl]
	);

	useEffect(() => {
		if (ssoRegister) {
			setSsoRegister(false);

			// Initialize the Microsoft Teams SDK
			microsoftTeams.initialize();

			const hideLoader = process.env.REACT_APP_HIDE_LOADER;

			if (hideLoader) {
				setHide(true);
			}

			// Get the user context from Teams
			microsoftTeams.getContext(async (context: microsoftTeams.Context) => {
				// if (context === null || context === undefined) {
				// 	setHide(true);
				// }

				localStorage.setItem("context", JSON.stringify(context));
				// setHide(false);
				try {
					let organization: any = await axios.post(
						`${baseURL}api/get-organisation-via-tenant`,
						{
							tenant_id: context.tid,
						}
					);

					if (organization && organization?.data?.data?.org) {
						if (checkAutoLogin) {
							setCheckAutoLogin(false);

							const { show_login_page } = organization.data.data.org;

							if (
								show_login_page === true ||
								show_login_page === false ||
								show_login_page === "true" ||
								show_login_page === "false"
							) {
								localStorage.setItem(
									"show_login_page",
									JSON.stringify(show_login_page)
								);

								setShowLoginPage(show_login_page);

								setHide(show_login_page);
							} else {
								setShowLoginPage(true);
								localStorage.setItem("show_login_page", JSON.stringify(true));

								setHide(true);
							}

							setShowLoginPage(show_login_page);

							if (show_login_page === false) {
								ssoAuth(context);
							}
						} else {
							ssoAuth(context);

							setHide(false);

							setShowLoginPage(true);
							localStorage.setItem("show_login_page", JSON.stringify(true));
						}
					}
				} catch (err) {
					setHide(true);

					setShowLoginPage(true);
					localStorage.setItem("show_login_page", JSON.stringify(true));

					Notification({
						message:
							"Organisation is not configured with VLE. Please contact your administrator.",
						type: "warning",
						title: "Warning",
					});
				}
			});
		}
	}, [
		run,
		baseURL,
		ssoAuth,
		ssoRegister,
		checkAutoLogin,
		setShowLoginPage,
		teamsLoginAuthUrl,
	]);

	useEffect(() => {
		if (isSuccess) {
			const userData = data?.data?.data;

			setMykademyUserData(userData);
			setUserRole(userData?.akademy?.user_data?.role_name.toLowerCase());
			setVleToken(userData?.vleToken);
			setDomain(userData?.userData.domain);
		}
	}, [
		data,
		setDomain,
		isSuccess,
		setVleToken,
		setUserRole,
		setMykademyUserData,
	]);

	useEffect(() => {
		if (isError) {
			setHide(true);

			const errorMsg = error?.response.data.message;

			Notification({
				message: errorMsg,
				type: "warning",
				title: "Warning",
			});

			// const isInvalidGrantError = errorMsg
			// 	? errorMsg.includes("invalid_grant")
			// 	: false;

			// if (isInvalidGrantError) {
			// 	microsoftTeams.getContext(async (context: microsoftTeams.Context) => {
			// 		const openSsoPopup = await ssoAuth(context);

			// 		openSsoPopup();
			// 	});
			// }
		}
	}, [error, isError, ssoAuth]);

	useEffect(() => {
		if (isSuccess) {
			const allMykademyDataSetToLocalStorage =
				mykademyUserData &&
				userRole &&
				vleToken &&
				(showLoginPage === true || showLoginPage === false);

			if (allMykademyDataSetToLocalStorage) {
				setAllDataSet(true);

				// The teams tab routing code below is duplicated instead of using the
				// hook useDeepLinkRouting because the hook method is not routing the
				// tab because of the asynchronous nature of setState.

				let entityId = "index";
				let routeName = "dashboard";

				const entityLabel = entityId;
				const appid = vleConfig.index.APP_ID;
				const baseUrl = vleConfig.index.APP_BASEURL;

				let encodedContext = encodeURI(`{"subEntityId": ${entityLabel}}`);

				let encodedWebUrl = encodeURI(`${baseUrl}${routeName}`);

				microsoftTeams.executeDeepLink(
					`https://teams.microsoft.com/l/entity/${appid}/${entityId}?webUrl=${encodedWebUrl}&context=${encodedContext}`
				);
			}
		}
	}, [
		history,
		userRole,
		vleToken,
		isSuccess,
		showLoginPage,
		deeplinkParams,
		mykademyUserData,
		setDeeplinkParams,
	]);

	return {
		hide,
		setHide,
		allDataSet,
		hideButtons,
		setHideButtons,
		setSsoRegister,
		setCheckAutoLogin,
	};
}

export { useGetTokenFromMS };
