import styled from "@emotion/styled/macro";

const AddImg = styled.img``;

const BtnText = styled.span`
	margin-left: 3px;
	font-size: 0.9rem;
`;

const CourseDetailButtonImg = styled.img``;

const ImgWrap = styled.div`
	height: 100%;
	max-height: 422px;
	position: relative;
`;

const ContentImgLg = styled.img`
	border-radius: 4px 0 0 4px;
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

const ContentDetailWrap = styled.div`
	padding: 30px 10px;
`;

const ContentDetail = styled.p`
	margin-bottom: 20px;
	color: #484848;
`;

const ContentDetailTitle = styled.h4`
	margin-bottom: 15px;
	font-weight: 600;
	font-size: 16px;
	color: #252424;
`;

const ContentDetailInfoWrap = styled.div`
	height: 182px;
	overflow-y: auto;
	margin-bottom: 20px;
	padding-right: 10px;

	::-webkit-scrollbar-track {
		border-radius: 4px;
	}

	::-webkit-scrollbar {
		width: 4px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 4px;

		background-color: hsl(238 26% 47% / 1);
		border: 1px solid #f5f5f5;
	}
`;

const ContentPricingWrap = styled.div`
	margin-bottom: 20px;
	flex-wrap: wrap;
	padding: 0;
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const ContentPrice = styled.span`
	margin-bottom: 15px;
	display: flex;
	align-items: center;
	font-size: 20px;
	color: #484848;
`;

const ContentTotalLectures = styled.span`
	margin-bottom: 5px;
	display: flex;
	align-items: center;
	color: #484848;
`;

const TotalLecturesImg = styled.img`
	width: 12px;
	margin-right: 10px;
`;

const ContentBtnWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

const CategoryWrap = styled.div`
	margin-bottom: 15px;
	display: flex;

	img {
		width: 19px;
		margin-right: 6px;
	}
`;

export {
	AddImg,
	BtnText,
	ImgWrap,
	ContentImgLg,
	ContentPrice,
	ContentDetail,
	ContentBtnWrap,
	CategoryWrap,
	TotalLecturesImg,
	ContentDetailWrap,
	ContentDetailTitle,
	ContentPricingWrap,
	ContentTotalLectures,
	ContentDetailInfoWrap,
	CourseDetailButtonImg,
};
