import React, { FC, useState } from "react";
import { useCartStateContext } from "context";
import { BsCheck, BsPersonDash } from "react-icons/bs";
import { Button } from "styles/components.styles";
import { AddImg, BtnText } from "styles/courses.styles";
import AddIcon from "assets/images/common/add-icon.svg";
import { useSubscribeBundle } from "../useSubscribeBundle";
import { BiLayerPlus } from "react-icons/bi";
import DefaultBundle from "assets/images/pathway/defaultPathway.jpg";
import { Spinner } from "react-bootstrap";
import { useIsMobileView } from "hooks";

interface IActionButtonProps {
  bundle: any;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  canUnenrollPaidCourse: boolean;
}

const BundleActionButton: FC<IActionButtonProps> = ({
  bundle,
  setIsOpen,
  canUnenrollPaidCourse,
}) => {
  const { c_is_free, isEnrolled, id } = bundle;
  const { isLoading: isSubscribing, mutate: subscribeBundle } =
    useSubscribeBundle({ bundleId: id });

  const { isTabletView } = useIsMobileView();

  const isFree = c_is_free === "1";

  const [added, setAdded] = useState(
    !!JSON.parse((localStorage.getItem("carts") as string) || "[]")?.find(
      (item: any) => item.id === bundle.id
    )
  );

  const { addToCart } = useCartStateContext();

  function addBundleToCart() {
    setAdded(true);
    if (!added) {
      addToCart({
        id: bundle?.id,
        title: bundle?.c_title,
        price: bundle?.c_price,
        description: bundle?.c_description,
        discount: bundle?.c_discount,
        courses: JSON.parse(bundle?.c_courses),
        currency: bundle?.currency,
        image: bundle?.c_image ?? DefaultBundle,
      });

      localStorage.setItem("bundleCart", "1");
    }
  }

  const subscribeToBundle = (bundleId: string) => {
    subscribeBundle({
      bundleId,
    });
  };

  const handleButtonVariant = () => {
    if (isFree) {
      return isEnrolled ? "danger" : "purple";
    } else {
      return isEnrolled ? "danger" : added ? "success" : "purple";
    }
  };

  const handleButtonClick = () => {
    if (isFree) {
      isEnrolled ? setIsOpen(true) : subscribeToBundle(id);
    } else {
      isEnrolled ? setIsOpen(true) : addBundleToCart();
    }
  };

  const handleButtonText = () => {
    if (isFree) {
      return isEnrolled ? "Unsubscribe" : "Subscribe";
    } else {
      return isEnrolled ? "Unsubscribe" : added ? "In cart" : "Add to cart";
    }
  };

  const handleButtonDisability = () => {
    if (isFree) {
      return isEnrolled ? false : isSubscribing;
    } else {
      return isEnrolled ? false : added;
    }
  };

  const handleButtonDisplay = () => {
    if (!isFree) {
      return isEnrolled ? (canUnenrollPaidCourse ? "flex" : "none") : "flex";
    }
  };

  const handleButtonIcon = () => {
    if (isFree) {
      return isSubscribing ? <Spinner size="sm" /> : <BiLayerPlus />;
    } else {
      return isEnrolled ? (
        <BsPersonDash
          style={{
            fontSize: "0.9rem",
          }}
        />
      ) : added ? (
        <BsCheck
          style={{
            height: "18px",
            width: "18px",
          }}
        />
      ) : (
        <AddImg src={AddIcon} alt="add icon" />
      );
    }
  };
  return (
    <Button
      variant={handleButtonVariant()}
      fontweight="600"
      onClick={handleButtonClick}
      disabled={handleButtonDisability()}
      style={{
        display: handleButtonDisplay(),
      }}
    >
      {!isTabletView && handleButtonIcon()}

      <BtnText>{handleButtonText()}</BtnText>
    </Button>
  );
};

export default BundleActionButton;
