import React, { Fragment, useEffect, useRef } from "react";
import { useLogout } from "hooks";
import { Button, FullPageSpinner } from "styles/components.styles";
import { useLocation } from "react-router-dom";
import BrokenRobotImage from "assets/images/newImages/broken-robot.png";

const FallBackUi = ({ error, resetErrorBoundary, height, background }: any) => {
  const { setLogout, logoutLoader } = useLogout();
  const { pathname } = useLocation();
  const previousRouteRef = useRef(pathname);

  useEffect(() => {
    // Get the previous and current routes
    const previousRoute = previousRouteRef.current;
    const currentRoute = pathname;

    // Compare the previous and current routes
    if (previousRoute !== currentRoute) {
      resetErrorBoundary();
    }

    // Update the previous route
    previousRouteRef.current = currentRoute;
  }, [pathname, resetErrorBoundary]);

  return (
    <Fragment>
      {logoutLoader ? (
        <FullPageSpinner />
      ) : (
        <div
          role="alert"
          style={{
            // color: "red",
            height: height ?? "calc(100vh - 10rem)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: height ? "4px" : "none",
            boxShadow: height
              ? "0 0 2px 0 rgb(0 0 0 / 12%), 0 2px 4px 0 rgb(0 0 0 / 14%)"
              : "none",
            border: height ? "solid 0 #e73550" : "none",
            backgroundColor: height ? "#fff" : "none",
          }}
        >
          <img
            style={{
              width: height ? "5rem" : "20rem",
            }}
            src={BrokenRobotImage}
            alt="broken-robot"
          />
          <p>Uh oh... There's a problem. Try refreshing the app.</p>
          <pre>{error.message}</pre>
          <div
            style={{ display: "flex", color: "black", alignItems: "center" }}
          >
            <span style={{ marginRight: "10px" }}>
              If refreshing the app doesn't work, Please
            </span>

            <Button
              variant="dark"
              padding="5px 10px"
              onClick={() => setLogout(true)}
            >
              Sign Out
            </Button>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default FallBackUi;
