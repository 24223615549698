/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import { Fragment } from "react";

import styled from "@emotion/styled/macro";

import { DragDropIcon } from "components/svg";

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const DropZoneChildren = ({ file, errorMsg, fileTypes }: any) => {
	return (
		<Fragment>
			<DragDropIcon />
			<TextWrapper>
				{/* {errorMsg ? (
					<p css={{ color: "red", fontSize: "1em" }}>
						Upload failed, please try again!
					</p>
				) :  */}

				{file ? (
					<p css={{ color: "green", fontSize: "1em" }}>
						Uploaded Successfully!
					</p>
				) : (
					<p css={{ fontSize: "1em" }}>
						Drag & Drop, or browse your file (Max size of 15 MB)
					</p>
				)}

				<p css={{ fontWeight: "bold", fontSize: "0.8em" }}>
					{fileTypes.join(", ").toUpperCase()}
				</p>
			</TextWrapper>
		</Fragment>
	);
};

export default DropZoneChildren;
