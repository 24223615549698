/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React from "react";

import { Alert } from "react-bootstrap";

const NoResultsFound = ({
  message,
  variant = "danger",
}: {
  message?: string;
  variant?: string;
}) => {
  return (
    <Alert
      variant={variant}
      css={{
        display: "flex !important",
        justifyContent: "center !important",
        alignItems: "center !important",
        margin: "0 !important",
      }}
    >
      <p
        css={{
          margin: "0 !important",
        }}
      >
        {message ? message : "No results found!"}
      </p>
    </Alert>
  );
};

export default NoResultsFound;
