import { FC, forwardRef } from "react";
import DateRangePicker from "react-datepicker";
import { DatePickerInputWrapper } from "./datePicker.styles";
import CalenderIcon from "assets/images/common/date-picker.svg";

interface IDatePickerProps {
  startDate: any;
  endDate: any;
  setDateRange: React.Dispatch<React.SetStateAction<any[]>>;
  cb?: () => void;
}

export const CustomInput = forwardRef(
  ({ value, onClick }: { value: any; onClick: any }, ref: any) => (
    <DatePickerInputWrapper>
      <input
        onClick={onClick}
        ref={ref}
        value={value}
        id="floatingDates"
        type="text"
        placeholder="Enter Date Range"
        readOnly
      />
      {!value ? <img src={CalenderIcon} alt="search" /> : null}
    </DatePickerInputWrapper>
  )
);

const DatePicker: FC<IDatePickerProps> = ({
  endDate,
  setDateRange,
  startDate,
}) => {
  return (
    <div>
      <DateRangePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setDateRange(update);
        }}
        customInput={<CustomInput value={undefined} onClick={undefined} />}
        isClearable={true}
        maxDate={new Date()}
      />
    </div>
  );
};

export default DatePicker;
