/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { Fragment, useState, useEffect } from "react";

// Third party imports
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { FaPencilAlt } from "react-icons/fa";

import { Input, NativeSelect } from "components";

import { useEditProfileSchema } from "./useEditProfileSchema";

import { Button, CustomHeightSpinner } from "styles/components.styles";

import {
  Text,
  Title,
  Image,
  Header,
  SubHeader,
  InputWrapper,
} from "components/profileInformation/profileInformation.styles";

import { ButtonWrapper, FormGroup, InputFile } from "styles/form.styles";

import { useUpdateProfileInfo } from "components/profileInformation/useUpdateProfileInfo";

import { useAsync, useImageUpload } from "hooks";

import {
  useVleTokenContext,
  useMykademyDataContext,
  usePortfolioDataContext,
} from "context";

// Utils imports
import { client } from "utils/api-client";

import { countryWithCode } from "utils/countryWithCode";

import defaultUserImage from "assets/images/customAssets/default_user.jpg";

import { Notification } from "utils/notification";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { useLogout } from "hooks";

const EditProfileInfo = ({ routeToPage, profileInfo }: any) => {
  const [currentImage, setCurrentImage] = useState("");

  const [open, setOpen] = useState(false);

  const { vleToken } = useVleTokenContext();

  const { schema } = useEditProfileSchema();

  const { updatedData, setUpdatedData } = useUpdateProfileInfo();

  const { isMykademy, mykademyUserData, setMykademyUserData } =
    useMykademyDataContext();
  const { isPortfolio } = usePortfolioDataContext();

  const { setLogout } = useLogout();

  const [logoutAfterUpdate, setLogoutAfterUpdate] = useState(false);

  const {
    register,
    setValue,
    clearErrors,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), reValidateMode: "onSubmit" });

  const { run, data, error, isSuccess, isError, isLoading } = useAsync();

  const { image, selectedFile, setImageUploadEvent } = useImageUpload();

  useEffect(() => {
    if (image) {
      setCurrentImage(image);
    }
  }, [image, setCurrentImage]);

  useEffect(() => {
    if (profileInfo) {
      const { firstName, lastName, phone, email, country, imageUrl } =
        profileInfo;

      setValue("First Name", firstName);
      setValue("Last Name", lastName);
      setValue("Email Address", email);
      setValue("Phone Number", phone);
      setValue("Country", country);
      setCurrentImage(imageUrl);
    }
  }, [profileInfo, setCurrentImage, setValue]);

  useEffect(() => {
    if (Object.keys(updatedData).length !== 0) {
      routeToPage("showProfileInfo");
    }
  }, [updatedData]);

  useEffect(() => {
    if (isSuccess && !logoutAfterUpdate) {
      setUpdatedData(data);

      localStorage.removeItem("vleToken");
      localStorage.setItem(
        "vleToken",
        JSON.stringify(data?.data?.data?.mykademy?.data?.vleToken)
      );

      Notification({
        message: "Profile info updated successfully",
        type: "success",
        title: "Success",
      });
    }
  }, [
    data,
    isSuccess,
    isMykademy,
    isPortfolio,
    routeToPage,
    mykademyUserData,
    setMykademyUserData,
  ]);

  useEffect(() => {
    if (isError) {
      Notification({
        message: "Unable to update profile info, please try again later",
        type: "warning",
        title: "Warning",
      });
    }
  }, [error, isError]);

  const updateInfo = async (data: any) => {
    const username = `${data["First Name"]} ${data["Last Name"]}`;

    let mykademyBody = {
      name: username,
      email: data["Email Address"],
      phone: data["Phone Number"],
      us_country_code: data.Country.split(" ")[0],
      us_country: data.Country.split(" ")[1],
      file: image,
    };

    let portfolioBody = {
      first_name: data["First Name"],
      last_name: data["Last Name"],
      email: data["Email Address"],
      phone: data["Phone Number"],
      us_country_code: data.Country.split(" ")[0],
      file: selectedFile,
    };

    let apiBody = isMykademy
      ? mykademyBody
      : isPortfolio
      ? portfolioBody
      : null;
    return run(
      client({
        method: "put",
        endpoint: "api/member/updateProfile",
        optional: {
          data: apiBody,
          token: vleToken,
        },
      })
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = () => {
    handleClose();
    setLogoutAfterUpdate(true);
    updateInfo(getValues()).then(async (res: any) => {
      if (res?.data?.status === "success") {
        setLogout(true);
      }
    });
  };

  const onSubmit = async (data: any) => {
    if (
      profileInfo?.email !== data["Email Address"] ||
      profileInfo?.phone !== data["Phone Number"]
    ) {
      handleClickOpen();
    } else {
      updateInfo(data);
    }
  };

  function onChangeHandler(e: any) {
    e.preventDefault();

    setImageUploadEvent(e);
  }

  return (
    <Fragment>
      {isLoading ? (
        <CustomHeightSpinner height="591px" />
      ) : (
        <Fragment>
          <Header>
            <Image
              src={currentImage ? currentImage : defaultUserImage}
              alt="profile image"
            />
            <InputWrapper>
              <FaPencilAlt css={{ cursor: "pointer" }} />
              <InputFile
                type="file"
                accept="image/png, image/jpg, image/jpeg"
                name="avatar"
                onChange={(e) => onChangeHandler(e)}
                onClick={(event: any) => {
                  event.target.value = null;
                }}
              />
            </InputWrapper>
            <Title>Profile</Title>
          </Header>
          <SubHeader>
            <Text>Personal</Text>
          </SubHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Input
                label="First Name"
                register={register}
                clearErrors={clearErrors}
                errors={errors}
                backgroundcolor="#f0f0f0"
                required
              />
            </FormGroup>
            <FormGroup>
              <Input
                label="Last Name"
                register={register}
                clearErrors={clearErrors}
                errors={errors}
                backgroundcolor="#f0f0f0"
                required
              />
            </FormGroup>

            <FormGroup>
              <Input
                label="Email Address"
                register={register}
                clearErrors={clearErrors}
                errors={errors}
                type="email"
                backgroundcolor="#f0f0f0"
                required
              />
            </FormGroup>

            <FormGroup>
              <NativeSelect
                label="Country"
                errors={errors}
                options={countryWithCode}
                register={register}
                clearErrors={clearErrors}
                backgroundcolor="#f0f0f0"
                height="41px"
                placeholder="Select a country"
              />
            </FormGroup>

            <FormGroup>
              <Input
                label="Phone Number"
                register={register}
                clearErrors={clearErrors}
                errors={errors}
                type="text"
                backgroundcolor="#f0f0f0"
                required
              />
            </FormGroup>

            <ButtonWrapper css={{ paddingBottom: "20px" }}>
              <Button
                type="button"
                variant="dark"
                fontweight="600"
                onClick={() => routeToPage("showProfileInfo")}
              >
                Back
              </Button>
              <Button
                type="submit"
                variant="purple"
                fontweight="600"
                css={{ marginLeft: "8px" }}
              >
                Update
              </Button>
            </ButtonWrapper>
          </form>
        </Fragment>
      )}
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          zIndex: 99999,
        }}
      >
        <DialogTitle
          style={{
            fontSize: "1rem",
            fontWeight: "normal",
          }}
        >
          {"Are you sure you want to update your information?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Making changes to your email or phone number will log you out of the
            application.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            variant="dark"
            fontweight="600"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="button"
            variant="purple"
            fontweight="600"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default EditProfileInfo;
