import { useLocation } from "react-router-dom";

const useGetQueryValue = (key: any) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const value = params.get(key);

  return { value };
};

export { useGetQueryValue };
