import { Alert } from "@mui/material";
import ErrorBoundary from "components/errorBoundary/errorBoundary";
import { useGetMemberGamificationDetails, useIsMobileView } from "hooks";
import React, { useState } from "react";
import { Spinner } from "styles/components.styles";

import AchievementBox from "./achievementBox";

import RedeemBox from "./redeemBox";

const ManualClaim = ({ setIsPopoverOpen }: any) => {
  const [isClaimButtonClicked, setIsClaimButtonClicked] = useState(false);

  const [optionsToBeConverted, setOptionsToBeConverted] = useState(null);

  const [optionsAfterConversion, setOptionsAfterConversion] = useState(null);

  const { isMobileView } = useIsMobileView();

  const { memberDetails, isLoading } = useGetMemberGamificationDetails();

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "300px",
          width: isMobileView ? "300px" : "30rem",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (!!!memberDetails?.allBadges) {
    return (
      <Alert
        severity="info"
        style={{
          width: "20rem",
          height: "10rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        No data found
      </Alert>
    );
  }

  return (
    <ErrorBoundary height="20rem" background="white">
      {isClaimButtonClicked ? (
        <RedeemBox
          memberDetails={memberDetails}
          optionsToBeConverted={optionsToBeConverted}
          optionsAfterConversion={optionsAfterConversion}
          setIsPopoverOpen={setIsPopoverOpen}
          setIsClaimButtonClicked={setIsClaimButtonClicked}
        />
      ) : (
        <AchievementBox
          isLoading={isLoading}
          memberDetails={memberDetails}
          setIsClaimButtonClicked={setIsClaimButtonClicked}
          setIsPopoverOpen={setIsPopoverOpen}
          setOptionsToBeConverted={setOptionsToBeConverted}
          setOptionsAfterConversion={setOptionsAfterConversion}
        />
      )}
    </ErrorBoundary>
  );
};

export default ManualClaim;
