const localStorageKeys = {
  msOid: "msOid",
  domain: "domain",
  vleToken: "vleToken",
  isLegacy: "isLegacy",
  userRole: "userRole",
  courseId: "courseId",
  eventLink: "eventLink",
  ssoSignup: "ssoSignup",
  legacyType: "legacyType",
  vcTokenData: "vcTokenData",
  powerBiToken: "powerBiToken",
  addedCourses: "addedCourses",
  pathwayToken: "pathwayToken",
  showLoginPage: "showLoginPage",
  selectedOption: "selectedOption",
  onboardingSteps: "onboardingSteps",
  mykademyUserData: "mykademyUserData",
  portfolioUserData: "portfolioUserData",
  mykademyOrganizationData: "mykademyOrganizationData",
};

export { localStorageKeys };
