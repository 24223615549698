import styled from "@emotion/styled";

const HeaderWrapper = styled.div`
  margin-bottom: 10px;
  p {
    color: gray;
    margin-top: -1px;
  }
`;

const ContainerWrapper = styled.div`
  padding: 0px 20px;
`;

const TitleWrapper = styled.div`
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  h2 {
    margin: 0 0 0 5px;
  }
`;

const ContentTitle = styled.h4`
  margin: 0 0 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #252424;
`;

const CourseContentWrapper = styled.div``;

const DiscountedPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const DiscountdPrice = styled.span``;

const CrossedPrice = styled.span`
  text-decoration: line-through;
  font-size: 1rem;
`;
export {
  ContentTitle,
  TitleWrapper,
  CrossedPrice,
  HeaderWrapper,
  DiscountdPrice,
  ContainerWrapper,
  CourseContentWrapper,
  DiscountedPriceWrapper,
};
