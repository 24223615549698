import React, { Fragment } from "react";

//Third Party imports
import { Badge, Popover } from "@material-ui/core";
import { BsThreeDots } from "react-icons/bs";

//Styles imports
import {
  BadgeIcon,
  BadgeListWrapper,
  PopoverBadgeListWrapper,
} from "./badgeList.styles";

const BadgeList = ({ badges }: any) => {
  const filteredBadges = badges?.filter((item: any) => item?.badge !== null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Fragment>
      <BadgeListWrapper>
        {filteredBadges?.length > 0
          ? filteredBadges?.slice(0, 3)?.map((item: any, index: any) => {
              return (
                <Badge
                  style={{
                    marginRight: "1rem",
                  }}
                  key={index}
                  badgeContent={item?.count}
                  color="primary"
                >
                  <BadgeIcon src={item?.badge?.icon} alt={item?.badge?.title} />
                </Badge>
              );
            })
          : "N/A"}
        {filteredBadges?.length > 3 && (
          <span onClick={handleClick}>
            <BsThreeDots
              style={{
                cursor: "pointer",
                fontSize: "1.3rem",
              }}
            />
          </span>
        )}
      </BadgeListWrapper>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        disableScrollLock={true}
      >
        <PopoverBadgeListWrapper>
          {filteredBadges?.slice(3)?.map((item: any, index: any) => {
            return (
              <Badge
                style={{
                  marginRight: "1rem",
                  marginTop: "1rem",
                }}
                badgeContent={item?.count}
                color="primary"
                key={index}
              >
                <BadgeIcon src={item?.badge?.icon} alt={item?.badge?.title} />
              </Badge>
            );
          })}
        </PopoverBadgeListWrapper>
      </Popover>
    </Fragment>
  );
};

export default BadgeList;
