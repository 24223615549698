import { useState, useEffect } from "react";

// Third party imports
import { useInfiniteQuery } from "react-query";

// Util imports
import { client } from "utils/api-client";
import { Notification } from "utils/notification";

// Context imports
import { usePathwayTokenContext, useVleTokenContext } from "context";
import { getUniqueValues } from "utils/removDuplicate";

function useGetLeaderboardData({ defaultPageLimit }: any) {
  const [leaderboardData, setLeaderboardData] = useState<any>(null);
  const [pageLimit, setPageLimit] = useState(defaultPageLimit);

  const { vleToken } = useVleTokenContext();

  const { pathwayToken } = usePathwayTokenContext();

  const getLeaderboardData = ({ pageParam = 1 }) => {
    return client({
      method: "get",
      endpoint: `api/reputation/leaderboard?page=${pageParam}&perPage=${pageLimit}&token=${pathwayToken}`,
      optional: {
        token: vleToken,
      },
    });
  };

  const {
    data,
    error,
    isLoading,
    isSuccess,
    isError,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery("leaderboardData", getLeaderboardData, {
    getNextPageParam: (_lastPage, pages) => {
      let totalData = pages[0]?.data?.data?.total;

      const totalPages = Math.ceil(totalData / pageLimit);

      if (pages.length < totalPages) {
        return pages.length + 1;
      } else {
        return undefined;
      }
    },

    refetchOnWindowFocus: false,

    enabled: !!pathwayToken,
  });

  useEffect(() => {
    if (isSuccess) {
      const allData: any = [];
      data?.pages?.map((group) => {
        group?.data?.data?.members?.map((item: any, index: any) => {
          const {
            name,
            leaderboardScore,
            email,
            isMyDetails,
            profilePic,
            rank,
            badges,
            reputationPoint,
          } = item;
          allData.push({
            name,
            email,
            totalPoints: leaderboardScore,
            rank,
            isSelf: isMyDetails,
            imageUrl: profilePic,
            achievedBadges: badges?.achievedBadges?.sort(),
            reputationPoint,
            reputationPointHistory: reputationPoint?.metadata,
            badgesHistory: badges?.metadata,
          });

          return allData;
        });
        if (group?.data?.data?.myDetails) {
          allData.push({
            name: group?.data?.data?.myDetails?.name,
            totalPoints: group?.data?.data?.myDetails?.leaderboardScore,
            rank: group?.data?.data?.myDetails?.rank,
            isSelf: true,
            imageUrl: group?.data?.data?.myDetails?.profilePic,
            email: group?.data?.data?.myDetails?.email,
            achievedBadges:
              group?.data?.data?.myDetails?.badges?.achievedBadges,
            reputationPoint: group?.data?.data?.myDetails?.reputationPoint,
          });
        }
        return allData;
      });

      setLeaderboardData(getUniqueValues(allData, "email"));
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (isError) {
      Notification({
        message: "Unable to get leaderboard data, please try again later",
        type: "warning",
        title: "Warning",
      });
    }
  }, [error, isError]);

  return {
    leaderboardData,
    isLoading,
    isSuccess,
    setPageLimit,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    pageLimit,
  };
}

export { useGetLeaderboardData };
