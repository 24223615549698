/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { Fragment, useEffect } from "react";

// Third party imports
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Input } from "components";

import { useEditPasswordSchema } from "./useEditPasswordSchema";

import { Button, CustomHeightSpinner } from "styles/components.styles";

import {
  Text,
  Title,
  Image,
  Header,
  SubHeader,
} from "components/profileInformation/profileInformation.styles";

import { ButtonWrapper, FormGroup } from "styles/form.styles";

import { useAsync } from "hooks";

// Utils imports
import { client } from "utils/api-client";

import { Notification } from "utils/notification";

import { useVleTokenContext } from "context";

import defaultUserImage from "assets/images/customAssets/default_user.jpg";

const EditPassword = ({ routeToPage, profileInfo }: any) => {
  const { schema } = useEditPasswordSchema();

  const {
    register,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), reValidateMode: "onSubmit" });

  const { run, error, isSuccess, isError, isLoading } = useAsync();

  const { vleToken } = useVleTokenContext();

  useEffect(() => {
    if (isSuccess) {
      Notification({
        message: "Password update complete",
        type: "success",
        title: "Success",
      });

      routeToPage("showProfileInfo");
    }
  }, [isSuccess, routeToPage]);

  useEffect(() => {
    if (isError) {
      const { message } = error?.response?.data;

      if (message) {
        Notification({
          message: message,
          type: "warning",
          title: "Warning",
        });
      } else {
        Notification({
          message: "Password update failed, please try again later",
          type: "warning",
          title: "Warning",
        });
      }
    }
  }, [isError, error]);

  const onSubmit = async (data: any) => {
    let apiBody = {
      current_password: btoa(data["Current Password"]),
      password: btoa(data["New Password"]),
      confirm_password: btoa(data["Confirm New Password"]),
    } as const;

    run(
      client({
        method: "post",
        endpoint: "api/resetpassword",
        optional: {
          data: apiBody,
          token: vleToken,
        },
      })
    );
  };

  const { imageUrl } = profileInfo;

  return (
    <Fragment>
      {isLoading ? (
        <CustomHeightSpinner height="471px" />
      ) : (
        <Fragment>
          <Header>
            <Image
              src={
                imageUrl
                  ? "https://" + imageUrl.split("https://").slice(-1)[0]
                  : defaultUserImage
              }
              alt="profile image"
            />
            <Title>Profile</Title>
          </Header>
          <SubHeader>
            <Text>Personal</Text>
          </SubHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Input
                label="Current Password"
                type="password"
                register={register}
                clearErrors={clearErrors}
                errors={errors}
                backgroundcolor="#f0f0f0"
                required
              />
            </FormGroup>
            <FormGroup>
              <Input
                label="New Password"
                type="password"
                register={register}
                clearErrors={clearErrors}
                errors={errors}
                backgroundcolor="#f0f0f0"
                required
              />
            </FormGroup>

            <FormGroup>
              <Input
                label="Confirm New Password"
                type="password"
                register={register}
                clearErrors={clearErrors}
                errors={errors}
                backgroundcolor="#f0f0f0"
                required
              />
            </FormGroup>

            <ButtonWrapper>
              <Button
                type="button"
                variant="dark"
                fontweight="600"
                onClick={() => routeToPage("showProfileInfo")}
              >
                Back
              </Button>
              <Button
                type="submit"
                variant="purple"
                fontweight="600"
                css={{ marginLeft: "8px" }}
              >
                Update
              </Button>
            </ButtonWrapper>
          </form>
        </Fragment>
      )}
    </Fragment>
  );
};

export default EditPassword;
