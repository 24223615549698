// Hook imports
import {
	localStorageKeys,
	useDeeplinkRouting,
	useLocalStorageState,
} from "hooks";

import { useLocation } from "react-router-dom";

function useViewCourseDeeplink() {
	const { setDeeplinkParams } = useDeeplinkRouting();

	const [, setCourseId] = useLocalStorageState({
		key: localStorageKeys.courseId,
	});

	const { pathname } = useLocation();

	console.log({ pathname });

	let isCourseTab =
		pathname.includes("course-list") || pathname.includes("coursesTab");

	console.log({ isCourseTab });

	function viewCourseDeeplink(courseId?: string) {
		if (courseId) {
			setCourseId(courseId);
		}

		setDeeplinkParams({
			entityId: "mycourses",
			routeName: "course-list",
			historyPush: false,
			pageRefresh: false,
			useReactRouter: isCourseTab,
		});
	}

	return { viewCourseDeeplink };
}

export { useViewCourseDeeplink };
