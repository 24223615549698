let msLegacySettingsItems = [
  {
    title: "Payment settings",
    icon: "PaymentIcon",
    route: "settings",
  },
  {
    title: "SSO Role settings",
    icon: "KeyIcon",
    route: "settings/ssoSettings",
  },

  {
    title: "Email settings",
    icon: "EmailIcon",
    route: "settings/emailSettings",
  },
  {
    title: "Admin settings",
    icon: "ProfileIcon",
    route: "settings/adminSettings",
  },
  {
    title: "Label settings",
    icon: "LabelIcon",
    route: "settings/labelSettings",
  },
];

let msSettingsWithPendingList = [
  {
    title: "Payment settings",
    icon: "PaymentIcon",
    route: "settings",
  },
  {
    title: "SSO Role settings",
    icon: "KeyIcon",
    route: "settings/ssoSettings",
  },

  {
    title: "Email settings",
    icon: "EmailIcon",
    route: "settings/emailSettings",
  },
  {
    title: "Admin settings",
    icon: "ProfileIcon",
    route: "settings/adminSettings",
  },
  {
    title: "Label settings",
    icon: "LabelIcon",
    route: "settings/labelSettings",
  },
  {
    title: "Pending User Invitation",
    icon: "ProfileIcon",
    route: "settings/pendingUserInvitation",
  },
];

let msSettingsWithoutPayment = [
  {
    title: "SSO Role settings",
    icon: "KeyIcon",
    route: "settings/ssoSettings",
  },

  {
    title: "Email settings",
    icon: "EmailIcon",
    route: "settings/emailSettings",
  },
  {
    title: "Admin settings",
    icon: "ProfileIcon",
    route: "settings/adminSettings",
  },
  {
    title: "Label settings",
    icon: "LabelIcon",
    route: "settings/labelSettings",
  },
  {
    title: "Pending User Invitation",
    icon: "ProfileIcon",
    route: "settings/pendingUserInvitation",
  },
];

let msSettingsWithoutPendingList = [
  {
    title: "Payment settings",
    icon: "PaymentIcon",
    route: "settings",
  },
  {
    title: "SSO Role settings",
    icon: "KeyIcon",
    route: "settings/ssoSettings",
  },
  {
    title: "Email settings",
    icon: "EmailIcon",
    route: "settings/emailSettings",
  },
  {
    title: "Admin settings",
    icon: "ProfileIcon",
    route: "settings/adminSettings",
  },
  {
    title: "Label settings",
    icon: "LabelIcon",
    route: "settings/labelSettings",
  },
];

let msSettingsWithoutPendingListAndPayment = [
  {
    title: "SSO Role settings",
    icon: "KeyIcon",
    route: "settings/ssoSettings",
  },
  {
    title: "Email settings",
    icon: "EmailIcon",
    route: "settings/emailSettings",
  },
  {
    title: "Admin settings",
    icon: "ProfileIcon",
    route: "settings/adminSettings",
  },
  {
    title: "Label settings",
    icon: "LabelIcon",
    route: "settings/labelSettings",
  },
];
let legacySettingsItems = [
  {
    title: "Payment settings",
    icon: "PaymentIcon",
    route: "settings",
  },
  {
    title: "Email settings",
    icon: "EmailIcon",
    route: "settings/emailSettings",
  },
  {
    title: "Admin settings",
    icon: "ProfileIcon",
    route: "settings/adminSettings",
  },
  {
    title: "Label settings",
    icon: "LabelIcon",
    route: "settings/labelSettings",
  },
];

let settingsWithPendingList = [
  {
    title: "Payment settings",
    icon: "PaymentIcon",
    route: "settings",
  },
  {
    title: "Email settings",
    icon: "EmailIcon",
    route: "settings/emailSettings",
  },
  {
    title: "Admin settings",
    icon: "ProfileIcon",
    route: "settings/adminSettings",
  },
  {
    title: "Label settings",
    icon: "LabelIcon",
    route: "settings/labelSettings",
  },
  {
    title: "Pending User Invitation",
    icon: "ProfileIcon",
    route: "settings/pendingUserInvitation",
  },
];
let settingsWithoutPaymentAndPendingList = [
  {
    title: "Email settings",
    icon: "EmailIcon",
    route: "settings/emailSettings",
  },
  {
    title: "Admin settings",
    icon: "ProfileIcon",
    route: "settings/adminSettings",
  },
  {
    title: "Label settings",
    icon: "LabelIcon",
    route: "settings/labelSettings",
  },
];
let settingsWithoutPendingList = [
  {
    title: "Payment settings",
    icon: "PaymentIcon",
    route: "settings",
  },
  {
    title: "Email settings",
    icon: "EmailIcon",
    route: "settings/emailSettings",
  },
  {
    title: "Admin settings",
    icon: "ProfileIcon",
    route: "settings/adminSettings",
  },
  {
    title: "Label settings",
    icon: "LabelIcon",
    route: "settings/labelSettings",
  },
];
let settingsWithoutPayment = [
  {
    title: "Email settings",
    icon: "EmailIcon",
    route: "settings/emailSettings",
  },
  {
    title: "Admin settings",
    icon: "ProfileIcon",
    route: "settings/adminSettings",
  },
  {
    title: "Label settings",
    icon: "LabelIcon",
    route: "settings/labelSettings",
  },
];
let tutorSettingsWithPendingList = [
  {
    title: "Pending User Invitation",
    icon: "ProfileIcon",
    route: "settings/pendingUserInvitation",
  },
];

export {
  legacySettingsItems,
  msLegacySettingsItems,
  settingsWithoutPayment,
  settingsWithPendingList,
  msSettingsWithoutPayment,
  msSettingsWithPendingList,
  settingsWithoutPendingList,
  msSettingsWithoutPendingList,
  tutorSettingsWithPendingList,
  settingsWithoutPaymentAndPendingList,
  msSettingsWithoutPendingListAndPayment,
};
