/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { Fragment } from "react";

// Third party imports
import parse from "html-react-parser";
import { Row, Col } from "react-bootstrap";

// Style imports
import { Button } from "styles/components.styles";
import {
	ImgWrap,
	ContentImgLg,
	ContentPrice,
	CategoryWrap,
	ContentDetail,
	ContentBtnWrap,
	TotalLecturesImg,
	ContentDetailWrap,
	ContentDetailTitle,
	ContentPricingWrap,
	ContentTotalLectures,
	ContentDetailInfoWrap,
} from "styles/courses.styles";

// Context imports
import { useModalContext } from "components/modal/modal";

// Image imports
import CategoryImg from "assets/images/getStarted/category.svg";
import TotalLecturesIcon from "assets/images/common/total-lectures.svg";

import { useGetQueryValue, useGetRecentCoursesMarketplace } from "hooks";

// Interface imports
import {
	SingleCourse,
	SingleCourseAdmin,
} from "ts/interfaces/courses.interface";
import ActionButton from "screens/coursesTab/marketPlaceCourseButton/actionButton";
import { useLocation } from "react-router-dom";
import {
	CrossedPrice,
	DiscountdPrice,
	DiscountedPriceWrapper,
} from "screens/coursesTab/learnerMarketPlace/coursesList/courseList.style";

interface AddContentDescriptionProps {
	course: any;
	isMarketPlace?: boolean;
	showDetailText?: boolean;
	addCourse?: any;
	isSelected?: boolean;
	removeCourse?: any;
	enablePayment?: boolean;
	showActionButtonLabel?: boolean;
	isDescriptionOnly?: boolean;
}

const AddContentDescription = ({
	course,
	isMarketPlace,
	showDetailText,
	enablePayment,
	showActionButtonLabel,
	isDescriptionOnly = false,
}: AddContentDescriptionProps) => {
	console.log({ course });
	const { pathname } = useLocation();
	const { setIsOpen } = useModalContext();
	const { value } = useGetQueryValue("bundle");

	const { coursesCategories } = useGetRecentCoursesMarketplace();

	const showCategories = (course: any) => {
		if (course.category) {
			let categoryName = "";

			coursesCategories.map((category: any) => {
				let splitted = course.category.split(",");
				if (splitted.includes(category.id)) {
					if (categoryName) {
						categoryName = `${categoryName},${category.ct_name}`;
					} else categoryName = category.ct_name;
				}

				return null;
			});

			return categoryName;
		}
	};

	function checkImageSrc() {
		if ("image" in course) {
			return course.image;
		} else if ("cb_image" in course) {
			return course.cb_image;
		} else {
			return "";
		}
	}

	function checkTitle() {
		if ("title" in course) {
			return course.title;
		} else if ("cb_title" in course) {
			return course.cb_title;
		} else {
			return "";
		}
	}

	function checkDescription() {
		if ("description" in course) {
			return course.description;
		} else if ("cb_description" in course) {
			return course.cb_description;
		} else if ("short_description" in course) {
			return course.short_description;
		} else {
			return "";
		}
	}

	function checkPrice() {
		const currencySymbol = course?.currency?.symbol;
		const isCartPage = pathname === "/course-list/cart";
		const priceWithDecimal = (price: string) =>
			`${currencySymbol}${parseFloat(price).toFixed(2)}`;
		const priceWithoutDecimal = (price: string) => `${currencySymbol}${price}`;

		if (!("price" in course) && !("cb_price" in course)) {
			return "";
		}

		const isDiscountDisabled =
			"discount" in course
				? course?.discount === "0"
				: course?.cb_discount === "0";
		const price = "price" in course ? course.price : course.cb_price;
		const discount =
			"discount" in course ? course.discount : course.cb_discount;

		const discountedPrice = (
			<DiscountedPriceWrapper>
				<DiscountdPrice>{priceWithDecimal(discount)}</DiscountdPrice>
				<CrossedPrice>{priceWithDecimal(price)}</CrossedPrice>
			</DiscountedPriceWrapper>
		);

		return isDiscountDisabled
			? isCartPage
				? priceWithDecimal(price)
				: priceWithoutDecimal(price)
			: discountedPrice;
	}

	return (
		<Row>
			<Col lg={6}>
				<ImgWrap>
					<ContentImgLg src={checkImageSrc()} alt={checkTitle()} />
				</ImgWrap>
			</Col>
			<Col lg={6}>
				<ContentDetailWrap>
					<ContentDetailTitle>{checkTitle()}</ContentDetailTitle>

					<ContentDetailInfoWrap>
						<ContentDetail>
							{checkDescription()
								? parse(checkDescription())
								: "No description available."}
						</ContentDetail>
					</ContentDetailInfoWrap>

					{isMarketPlace && value !== "1" ? (
						<CategoryWrap>
							<img src={CategoryImg} alt="category" />
							<span>{showCategories(course)}</span>
						</CategoryWrap>
					) : null}

					{isMarketPlace ? (
						<ContentPrice>
							{course.is_free === "1" ? "Free" : checkPrice()}
						</ContentPrice>
					) : null}

					{(course?.courses?.length || course?.total_lectures) &&
					!isDescriptionOnly ? (
						<ContentPricingWrap>
							<ContentTotalLectures>
								<TotalLecturesImg
									src={TotalLecturesIcon}
									alt="total lectures"
								/>
								{value === "1" ? course.courses.length : course.total_lectures}{" "}
								{value === "1" ? "Courses" : "Total Lectures"}
							</ContentTotalLectures>
						</ContentPricingWrap>
					) : null}

					{isDescriptionOnly ? null : (
						<ContentBtnWrap>
							{pathname === "/course-list/cart" ? null : (
								<ActionButton
									course={course}
									showDetailText={showDetailText}
									enablePayment={enablePayment}
									showActionButtonLabel={showActionButtonLabel}
								/>
							)}

							<Button
								variant="dark"
								fontweight="600"
								css={{ marginLeft: "8px" }}
								padding="5px 10px"
								onClick={() => setIsOpen(false)}
							>
								Close
							</Button>
						</ContentBtnWrap>
					)}
				</ContentDetailWrap>
			</Col>
		</Row>
	);
};

export default AddContentDescription;
