/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { useState, useEffect, Fragment, useRef } from "react";

// Third party imports
import { useHistory, useLocation } from "react-router-dom";

// Component imports
import NavbarDropdown from "components/navbar/navbarDropdown";

import {
  KeyIcon,
  PlanIcon,
  EmailIcon,
  LabelIcon,
  ProfileIcon,
  CoursesIcon,
  SuitcaseIcon,
  AdminToolsIcon,
} from "components/svg";

// Hook imports
import { useModalContext } from "components/modal/modal";
import { useLogout, useLinkSso, useIsMobileView } from "hooks";

// Style imports
import { Button, FullPageSpinner } from "styles/components.styles";

import {
  MenuItemsLi,
  MenuItemsTitle,
  MenuItemsImage,
} from "components/navbar/navbar.styles";

import { OliveLinkTransparent } from "screens/aiContentPro/aiContent.styles";

// Asset imports
import RightArrowIcon from "assets/images/common/arrow-right.svg";
import ArrowDownblue from "assets/images/common/arrow-down-blue.svg";

import BranchIcon from "assets/images/icon/branch.svg";
import BatchesIcon from "assets/images/mykademyAdmin/files.png";
import PathwayIcon from "assets/images/mykademyAdmin/pathway.svg";
import BadgeIcon from "assets/images/mykademyAdmin/badge.svg";

import ReputationIcon from "assets/images/mykademyAdmin/reputation.svg";

import EnrollmentIcon from "assets/images/icon/pathwayEnrollment.svg";
import TrophyIcon from "assets/images/gamification/trophy.png";

// import ReportIcon from "assets/images/mykademyAdmin/report.png";
import { MdOutlinePayment } from "react-icons/md";
import ReportIcon from "assets/images/icon/report.png";
import EnrollIcon from "assets/images/icon/enroll.png";
import BundlesIcon from "assets/images/mykademyAdmin/layer.png";
import WindowsIcon from "assets/images/common/windows-icon.svg";
import PowerBiIcon from "assets/images/mykademyAdmin/powerBi.svg";
import LearnersIcon from "assets/images/getStarted/learners-icon.svg";
import CourseListIcon from "assets/images/getStarted/courses-icon.svg";
import VideoClassroomIcon from "assets/images/getStarted/video-classroom-icon.svg";

import { Badge, Popover } from "@material-ui/core";

import { ManualClaim } from "components/gamification";
import { BiBell } from "react-icons/bi";

interface SubMenusParams {
  title: string;
  icon: any;
}

interface MenuItemDataProps {
  title: string;
  icon: any;
  route?: string;

  submenus?: SubMenusParams[];
}

interface MenuItemsProps {
  hasPendingBill?: boolean;
  showSsoButton: any;
  depthLevel: number;
  item: MenuItemDataProps;
  setType: React.Dispatch<React.SetStateAction<string>>;
}

const MenuItems = ({
  item,
  setType,
  depthLevel,
  showSsoButton,
  hasPendingBill,
}: MenuItemsProps) => {
  const [dropdown, setDropdown] = useState(false);

  const history = useHistory();

  const { setLinkSso } = useLinkSso({ url: null });

  const { isSmallTabletView } = useIsMobileView();

  const { setLogout, logoutLoader } = useLogout();

  const { setIsOpen } = useModalContext();

  const route = item?.route ?? "";

  const submenus = item?.submenus ?? null;

  const ref = useRef<HTMLLIElement>(null);

  const depthLeveLGreaterThanZero = depthLevel > 0;

  const [isPopoverOpen, setIsPopoverOpen] =
    React.useState<HTMLButtonElement | null>(null);

  const { pathname } = useLocation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsPopoverOpen(event.currentTarget);
  };

  const handleClose = () => {
    setIsPopoverOpen(null);
  };

  const open = Boolean(isPopoverOpen);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    const handler = (event: any) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  function routeToDestination(route: string, title: string) {
    if (route) {
      history.push(route);
    } else if (item.title === "Sign out") {
      setLogout(true);
    } else if (
      item.title === "Mykademy Profile" ||
      item.title === "Myportfolio Profile" ||
      item.title === "VLE Profile"
    ) {
      setType("Profile");
      setIsOpen(true);
    } else if (item.title === "Help Resources") {
      setType("Help Resources");
      setIsOpen(true);
    }

    // else if (item.title === "Configure Microsoft SSO") {
    // 	setLinkSso(true);
    // }
  }

  function getIcon(icon: string) {
    if (icon === "ProfileIcon") {
      return <ProfileIcon />;
    } else if (icon === "KeyIcon") {
      return <KeyIcon />;
    } else if (icon === "PlanIcon") {
      return <PlanIcon />;
    } else if (icon === "EmailIcon") {
      return <EmailIcon />;
    } else if (icon === "AdminToolsIcon") {
      return <AdminToolsIcon />;
    } else if (icon === "CoursesIcon") {
      return <CoursesIcon />;
    } else if (icon === "SuitcaseIcon") {
      return <SuitcaseIcon />;
    } else if (icon === "LearnersIcon") {
      return <img src={LearnersIcon} alt="learners icon" />;
    } else if (icon === "VideoIcon") {
      return <img src={VideoClassroomIcon} alt="video icon" />;
    } else if (icon === "CourseListIcon") {
      return <img src={CourseListIcon} alt="course list icon" />;
    } else if (icon === "BranchIcon") {
      return (
        <img src={BranchIcon} css={{ padding: "3px" }} alt="branch icon" />
      );
    } else if (icon === "BatchesIcon") {
      return (
        <img src={BatchesIcon} css={{ padding: "3px" }} alt="batches icon" />
      );
    } else if (icon === "ReportIcon") {
      return (
        <img src={ReportIcon} css={{ padding: "3px" }} alt="report icon" />
      );
    } else if (icon === "EnrollIcon") {
      return (
        <img src={EnrollIcon} css={{ padding: "3px" }} alt="enroll icon" />
      );
    } else if (icon === "BundlesIcon") {
      return (
        <img src={BundlesIcon} css={{ padding: "3px" }} alt="bundles icon" />
      );
    } else if (icon === "PathwayIcon") {
      return (
        <img src={PathwayIcon} css={{ padding: "3px" }} alt="pathway icon" />
      );
    } else if (icon === "BadgeIcon") {
      return <img src={BadgeIcon} css={{ padding: "1px" }} alt="badge icon" />;
    } else if (icon === "ReputationIcon") {
      return <img src={ReputationIcon} alt="reputation icon" />;
    } else if (icon === "EnrollmentIcon") {
      return (
        <img
          src={EnrollmentIcon}
          css={{ padding: "3px" }}
          alt="enrollment icon"
        />
      );
    } else if (icon === "LabelIcon") {
      return <LabelIcon />;
    } else if (icon === "PaymentIcon") {
      return <MdOutlinePayment color="#6264a7" />;
    } else if (icon === "PowerBiIcon") {
      return (
        <img src={PowerBiIcon} css={{ padding: "3px" }} alt="enrollment icon" />
      );
    } else if (icon === "TrophyIcon") {
      if (pathname === "/myAchievements") {
        return (
          <img
            src={TrophyIcon}
            css={{ padding: "3px" }}
            style={{
              width: "1.8rem",
              height: "1.8rem",
            }}
            alt="trophy icon"
          />
        );
      }
      return (
        <Badge
          badgeContent={
            <BiBell
              style={{
                fontSize: "8px",
              }}
            />
          }
          color="error"
        >
          <img
            src={TrophyIcon}
            css={{ padding: "3px" }}
            style={{
              width: "1.8rem",
              height: "1.8rem",
            }}
            alt="trophy icon"
          />
        </Badge>
      );
    }
  }

  function onSubMenuClick(title: string, route: string | undefined) {
    if (route && !isSmallTabletView) {
      history.push(route);
    } else {
      setDropdown((prev) => !prev);
    }
  }

  const onMouseEnter = () => {
    if (depthLeveLGreaterThanZero && !isSmallTabletView) {
      setDropdown(true);
    }
  };

  const onMouseLeave = (event: any) => {
    if (depthLeveLGreaterThanZero && !isSmallTabletView) {
      setDropdown(false);
    }
  };

  const dropDownButtonVariant = depthLeveLGreaterThanZero
    ? "blackText"
    : "purpleText";

  const dropDownButtonPadding = depthLeveLGreaterThanZero ? "10px 15px" : "";

  const dropDownHoverColor = depthLeveLGreaterThanZero ? "#6264a7" : "";
  const dropDownHoverBgColor = depthLeveLGreaterThanZero
    ? "rgba(98,100,183,.07058823529411765)"
    : "";

  return (
    <Fragment>
      {logoutLoader ? (
        <FullPageSpinner />
      ) : (
        <MenuItemsLi
          ref={ref}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {submenus ? (
            <Fragment>
              <Button
                variant={dropDownButtonVariant}
                hoverColor={dropDownHoverColor}
                hoverBgColor={dropDownHoverBgColor}
                onClick={() => onSubMenuClick(item.title, route)}
                css={{
                  width: "100%",
                  padding: dropDownButtonPadding,
                }}
              >
                <div
                  css={{
                    flex: 1,
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {getIcon(item?.icon)}
                  <MenuItemsTitle depthLevel={depthLevel} dropdown={dropdown}>
                    {item.title}
                  </MenuItemsTitle>
                </div>

                {depthLeveLGreaterThanZero && !isSmallTabletView ? (
                  <img src={RightArrowIcon} alt="right arrow" />
                ) : (
                  <MenuItemsImage
                    src={ArrowDownblue}
                    alt="arrow down blue"
                    dropdown={dropdown}
                  />
                )}
              </Button>
              <NavbarDropdown
                setType={setType}
                submenus={submenus}
                dropdown={dropdown}
                depthLevel={depthLevel}
                showSsoButton={showSsoButton}
              />
            </Fragment>
          ) : (
            <Fragment>
              {/* @ts-ignore */}
              {hasPendingBill && item?.title === "AI Content Pro" ? (
                <OliveLinkTransparent
                  href="https://contentpro.ai/"
                  target="_blank"
                  className="btn olive-ai-btn"
                >
                  Olive AI Content Pro
                </OliveLinkTransparent>
              ) : //  @ts-ignore
              item?.title === "Configure Microsoft SSO" ? (
                showSsoButton && !isSmallTabletView ? (
                  <Button
                    variant="microsoftBg"
                    onClick={() => setLinkSso(true)}
                  >
                    {getIcon(item?.icon)}
                    <MenuItemsTitle dropdown={dropdown}>
                      {item?.title}
                      <img
                        css={{ marginLeft: "10px", display: "flex" }}
                        src={WindowsIcon}
                        alt="Configure Microsoft SSO"
                      />
                    </MenuItemsTitle>
                  </Button>
                ) : null
              ) : item?.title === "Help Resources" ? (
                <span
                  css={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: dropDownButtonPadding,
                    cursor: "pointer",
                  }}
                  onClick={() => routeToDestination(route, item?.title)}
                >
                  <SuitcaseIcon />
                  <span
                    css={{
                      marginLeft: "5px",
                      color: "#212529",
                      fontSize: "14px",
                    }}
                  >
                    Help Resources
                  </span>
                </span>
              ) : item?.title === "Gamification" ? (
                <span
                  css={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: dropDownButtonPadding,
                    cursor: "pointer",
                  }}
                  aria-describedby={id}
                  onClick={handleClick}
                >
                  {getIcon(item?.icon)}
                </span>
              ) : (
                <Button
                  variant={dropDownButtonVariant}
                  hoverColor={dropDownHoverColor}
                  hoverBgColor={dropDownHoverBgColor}
                  onClick={() => routeToDestination(route, item?.title)}
                  css={{
                    width: "100%",
                    justifyContent: "flex-start",
                    padding: dropDownButtonPadding,
                  }}
                >
                  <span>{getIcon(item?.icon)}</span>
                  <MenuItemsTitle dropdown={dropdown}>
                    {item?.title}
                  </MenuItemsTitle>
                </Button>
              )}
            </Fragment>
          )}
        </MenuItemsLi>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={isPopoverOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableScrollLock={true}
      >
        <ManualClaim setIsPopoverOpen={setIsPopoverOpen} />
      </Popover>
    </Fragment>
  );
};

export default MenuItems;
