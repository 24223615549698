import React, { useState, useMemo } from "react";

interface ActivityHistoryFilterContextParams {
  rewardType: any;
  setRewardType: (state: any) => void;
  dateRange: string | null;
  setDateRange: React.Dispatch<React.SetStateAction<null[]>>;
  activity: any;
  setActivity: React.Dispatch<React.SetStateAction<any>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

const ActivityHistoryFilterContext = React.createContext<
  ActivityHistoryFilterContextParams | undefined
>(undefined);

ActivityHistoryFilterContext.displayName = "ActivityHistoryFilterContext";

function ActivityHistoryFilterProvider(props: any) {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [rewardType, setRewardType] = useState<string>("");
  const [search, setSearch] = useState<string>("");

  const [activity, setActivity] = useState<boolean>(false);

  const value = useMemo(() => {
    return {
      dateRange,
      setDateRange,
      rewardType,
      setRewardType,
      activity,
      setActivity,
      search,
      setSearch,
    };
  }, [dateRange, rewardType, activity, search]);

  return <ActivityHistoryFilterContext.Provider value={value} {...props} />;
}

function useActivityHistoryFilterContext() {
  const context = React.useContext(ActivityHistoryFilterContext);

  if (context === undefined) {
    throw new Error(
      `useActivityHistoryFilterContext must be used within a ActivityHistoryFilterProvider`
    );
  }

  return context;
}

export { ActivityHistoryFilterProvider, useActivityHistoryFilterContext };
