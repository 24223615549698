import styled from "@emotion/styled/macro";
import * as mq from "styles/media-queries";

interface DropzoneProps {
	isSizeError: boolean;
}

const DropzoneWrapper = styled.div<DropzoneProps>`
	.drop_area {
		position: relative;
		display: flex;
		align-items: center;
		min-width: 322px;
		border-radius: 5px;
		cursor: pointer;
		flex-grow: 0;

		flex-direction: column;
		padding: 20px;
		border: dashed 2px #585a96;
		height: 207px;
		max-width: 100%;

		${mq.smallPhone} {
			min-width: auto;
		}

		${mq.tabletWide} {
			min-width: 322px;
		}
	}

	svg {
		width: 75px;
		height: 75px;
		margin-bottom: 12px;
		flex: ${({ isSizeError }) => (isSizeError ? 1 : "auto")};

		path {
			fill: #585a96;
		}
	}
`;

export { DropzoneWrapper };
