import { useState, useEffect } from "react";

// Third party imports
import { useInfiniteQuery } from "react-query";

// Util imports
import { client } from "utils/api-client";
import { Notification } from "utils/notification";
import showGamificationMessage from "utils/showGamificationMessage";

// Context imports
import { useMykademyDataContext, useVleTokenContext } from "context";
import { useAuthorizeLearnerPathway } from "hooks/useAuthorizeLearnerPathway";

//Image imports
import DefaultDocument from "assets/images/pathway/default_document_resized.png";
import DefaultCourse from "assets/images/pathway/defaultPathway.jpg";

function useGetPathwayList() {
  const [pathwayList, setPathwayList] = useState<any>(null);
  const [pageLimit, setPageLimit] = useState(8);

  const { vleToken } = useVleTokenContext();
  const {
    mykademyUserData: {
      userData: { mk_member_id },
    },
  } = useMykademyDataContext();

  const { pathwayToken, isLoading: isAuthorizing } =
    useAuthorizeLearnerPathway();

  const getPathwayList = ({ pageParam = 1 }) => {
    return client({
      method: "get",
      endpoint: "api/learning-pathway/enrolled-courses",
      optional: {
        token: vleToken,
        params: {
          token: pathwayToken,
          learner: mk_member_id,
          limit: pageLimit,
          pageNumber: pageParam,
        },
      },
    });
  };

  const {
    data,
    error,
    isSuccess,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery("pathwayList", getPathwayList, {
    getNextPageParam: (_lastPage, pages) => {
      let coursesCount = pages[0]?.data?.data?.total;

      const totalPages = Math.ceil(coursesCount / pageLimit);

      if (pages.length < totalPages) {
        return pages.length + 1;
      } else {
        return undefined;
      }
    },
    refetchOnWindowFocus: false,
    enabled: !!pathwayToken,
  });

  useEffect(() => {
    if (isSuccess) {
      let allPathways: any = [];
      data?.pages?.map((page: any) => {
        showGamificationMessage({
          gamification: page?.data?.data?.data?.gamification,
        });
        return page?.data?.data?.data?.data?.map((item: any) => {
          return allPathways.push({
            id: item?._id,
            title: item.title,
            pathway_progress: item.pathway_progress
              ? item.pathway_progress
              : "0",
            courses: item.components?.map((component: any) => {
              if (component?.metadata?.course) {
                component.metadata.course.type = "course";
                component.metadata.course.cb_image =
                  component.metadata.course.cb_image ?? DefaultCourse;
              } else {
                component.metadata.document.type = "document";
                component.metadata.document.cb_image = DefaultDocument;
              }

              return (
                component?.metadata?.course || component?.metadata?.document
              );
            }),
            documentDetails: item.components?.map(
              (component: any) => component?.metadata?.documentDetails
            ),
          });
        });
      });
      setPathwayList(allPathways);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (isError) {
      console.log({ error });

      Notification({
        message: "Unable to get pathwayList, please try again later",
        type: "warning",
        title: "Warning",
      });
    }
  }, [error, isError]);

  return {
    isLoading: isLoading || isAuthorizing,
    pathwayList,
    fetchNextPage,
    hasNextPage,
    isSuccess,
  };
}

export { useGetPathwayList };
