import React, { useState, useMemo } from "react";

interface PathwayLibraryFilterContextParams {
  search: string | null;
  setSearch: React.Dispatch<React.SetStateAction<any>>;
  sortBy: any;
  setSortBy: React.Dispatch<React.SetStateAction<any>>;
  sort: any;
  setSort: React.Dispatch<React.SetStateAction<any>>;
}

const PathwayLibraryFilterContext = React.createContext<
  PathwayLibraryFilterContextParams | undefined
>(undefined);

PathwayLibraryFilterContext.displayName = "PathwayLibraryFilterContext";

function PathwayLibraryFilterProvider(props: any) {
  const [search, setSearch] = useState(null);
  const [sortBy, setSortBy] = useState({
    label: "Created Date",
    value: "created",
  });
  const [sort, setSort] = useState({
    label: "Ascending Order",
    value: "1",
  });

  const value = useMemo(() => {
    return {
      search,
      sortBy,
      setSortBy,
      sort,
      setSort,
      setSearch,
    };
  }, [search, sort, sortBy]);

  return <PathwayLibraryFilterContext.Provider value={value} {...props} />;
}

function usePathwayLibraryFilterContext() {
  const context = React.useContext(PathwayLibraryFilterContext);

  if (context === undefined) {
    throw new Error(
      `usePathwayLibraryFilterContext must be used within a PathwayLibraryFilterProvider`
    );
  }

  return context;
}

export { PathwayLibraryFilterProvider, usePathwayLibraryFilterContext };
