import { useGamificationContext, useVleTokenContext } from "context";
import { useAuthorizeLearnerPathway } from "hooks/useAuthorizeLearnerPathway";
import { useState, useEffect } from "react";

// Third party imports
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";

// Util imports
import { client } from "utils/api-client";
import { Notification } from "utils/notification";

const initialGamificationData = {
  data: [],
  activityTypes: [
    {
      labelKey: "title",
      valueKey: "id",
      type: "Courses",
      data: [],
    },

    {
      labelKey: "title",
      valueKey: "id",
      type: "Bundles",
      data: [],
    },
  ],
};

const initialBadgesActivities = [
  {
    id: "REPUTATION_POINT",
    name: "Reputation Point",
    type: "REPUTATION_POINT",
  },
  {
    id: "BADGE",
    name: "Badge",
    type: "BADGE",
  },
  {
    id: "EVENT",
    name: "Event",
    type: "EVENT",
  },
];

function useGetGamificationData() {
  const { pathname } = useLocation();
  const { reputationActivities, badgeActivities, badgeLabel } =
    useGamificationContext();
  const { isLoading: isAuthorizing, pathwayToken } =
    useAuthorizeLearnerPathway();
  const isReputationPointsPage = pathname === "/mykademyAdmin/reputationPoints";
  const [gamificationData, setGamificationData] = useState<any>(
    isReputationPointsPage
      ? initialGamificationData
      : {
          ...initialGamificationData,
          badgeName: badgeLabel,
        }
  );
  const { vleToken } = useVleTokenContext();

  const getGamificationData = () => {
    return client({
      method: "get",
      endpoint: `api/pre-gamification-data?token=${pathwayToken}`,
      optional: {
        token: vleToken,
      },
    });
  };

  const { data, error, isLoading, isSuccess, isError } = useQuery(
    "gamificationData",
    getGamificationData
  );

  useEffect(() => {
    if (isSuccess && badgeActivities && badgeActivities?.length > 0) {
      setGamificationData((prevData: any) => {
        const getActivityTypes = () => {
          let activityTypes = [
            {
              labelKey: "title",
              valueKey: "id",
              type: "Auth",
              data: [{}],
            },
          ];
          if (
            data?.data?.data?.courses &&
            data?.data?.data?.courses?.length > 0
          ) {
            activityTypes.push({
              labelKey: "title",
              valueKey: "id",
              type: "Courses",
              data: data?.data?.data?.courses,
            });
          }
          if (
            data?.data?.data?.bundles &&
            data?.data?.data?.bundles?.length > 0
          ) {
            activityTypes.push({
              labelKey: "title",
              valueKey: "id",
              type: "Bundles",
              data: data?.data?.data?.bundles,
            });
          }
          if (
            data?.data?.data?.pathways &&
            data?.data?.data?.pathways?.length > 0
          ) {
            activityTypes.push({
              labelKey: "title",
              valueKey: "_id",
              type: "Pathways",
              data: data?.data?.data?.pathways,
            });
          }
          return activityTypes;
        };
        return {
          ...prevData,

          data: isReputationPointsPage
            ? reputationActivities
            : [...initialBadgesActivities, ...badgeActivities],
          activityTypes: getActivityTypes(),
        };
      });
    }
  }, [
    data,
    pathname,
    isSuccess,
    badgeActivities,
    isReputationPointsPage,
    reputationActivities,
  ]);

  useEffect(() => {
    if (isError) {
      Notification({
        message: "Unable to get gamification data, please try again later",
        type: "warning",
        title: "Warning",
      });
    }
  }, [error, isError]);

  return {
    gamificationData,
    isLoading: isLoading || isAuthorizing,
  };
}

export { useGetGamificationData };
