import React, { useState, useMemo } from "react";

interface coursesFilterContextParams {
  filterValue: string | null;
  dataView: string | null;
  setFilterValue: (state: string) => null;
  setDataView: (state: string) => null;
  isClientCourseFree: string;
  setIsClientCourseFree: (state: string) => null;
}

const CoursesFilterContext = React.createContext<
  coursesFilterContextParams | undefined
>(undefined);

CoursesFilterContext.displayName = "CoursesFilterContext";

function CoursesFilterProvider(props: any) {
  const [filterValue, setFilterValue] = useState(null);
  const [dataView, setDataView] = useState("grid");
  const [isClientCourseFree, setIsClientCourseFree] = useState("");

  const value = useMemo(() => {
    return {
      isClientCourseFree,
      dataView,
      setIsClientCourseFree,
      filterValue,
      setDataView,
      setFilterValue,
    };
  }, [isClientCourseFree, dataView, filterValue]);

  return <CoursesFilterContext.Provider value={value} {...props} />;
}

function useCoursesFilterContext() {
  const context = React.useContext(CoursesFilterContext);

  if (context === undefined) {
    throw new Error(
      `useCoursesFilterContext must be used within a CoursesFilterProvider`
    );
  }

  return context;
}

export { CoursesFilterProvider, useCoursesFilterContext };
