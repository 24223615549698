/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { useEffect, useState } from "react";

//Component imports
import PathwayImageStack from "../pathwayImageStack";

// Style imports
import { Button, BoxShadow } from "styles/components.styles";
import {
  Text,
  Title,
  Wrapper,
  Progress,
  ProgressBar,
  ButtonWrapper,
  ProgressWrapper,
  TopCourseWrapper,
  ProgressCompleted,
} from "components/coursesCommonWidget/coursesCommonWidget.styles";

const TopPathway = ({ title, topPathway, deeplinkHandler }: any) => {
  const { courses, pathway_progress, id } = topPathway;
  const [imgArray, setImgArray] = useState([]);

  useEffect(() => {
    if (courses && courses.length > 0) {
      setImgArray(courses);
    }
  }, [courses, imgArray]);

  //Route to coursesTab and set pathway data in local storage
  const handleDeepLink = () => {
    const pathwayData = {
      id,
      title,
      progress: pathway_progress,
    };

    deeplinkHandler();
    localStorage.setItem("pathwayData", JSON.stringify(pathwayData));
  };

  const buttonText = parseInt(pathway_progress) ? "Resume" : "Start";
  return (
    <BoxShadow
      css={{
        marginTop: "15px",
        border: "none",
        display: "flex",
        padding: "0",
        boxShadow: "0 2px 4px rgb(0 0 0 / 10%)",
      }}
    >
      <Wrapper>
        <PathwayImageStack
          imageHeight="130px"
          imgArray={imgArray}
          isHomeTab={true}
        />

        <TopCourseWrapper>
          <Text>Jump back in</Text>
          <Title>{topPathway?.title}</Title>
          <ButtonWrapper>
            <Button
              variant="purple"
              hoverColor="white"
              onClick={() => handleDeepLink()}
              css={{ fontSize: "12px", marginRight: "10px" }}
            >
              {buttonText}
            </Button>
            <ProgressWrapper>
              <ProgressCompleted>{`Progress: ${
                pathway_progress ? pathway_progress : "0"
              } %`}</ProgressCompleted>
              <Progress>
                <ProgressBar
                  role="progressbar"
                  background={
                    pathway_progress === "100" ? "#13a10e" : "#6264a7"
                  }
                  css={{
                    width: pathway_progress + "%",
                    ariaValuenow: "25",
                    ariaValuemin: "0",
                    ariaValuemax: "100",
                  }}
                />
              </Progress>
            </ProgressWrapper>
          </ButtonWrapper>
        </TopCourseWrapper>
      </Wrapper>
    </BoxShadow>
  );
};

export default TopPathway;
