import React from "react";
import RewardIcon from "assets/images/gamification/reward.png";
import {
  CloseButton,
  IconImage,
  MessageHeading,
  SuccessBoxContainer,
  SuccessText,
} from "./pointCalculationSuccessBox.styles";
import { RxCross2 } from "react-icons/rx";
import Lottie from "lottie-react";
import animationData from "assets/lottieData/celebration.json";

const PointCalculationSuccessBox = ({ message }: any) => {
  return (
    <SuccessBoxContainer
      style={{
        position: "relative",
      }}
    >
      <Lottie
        animationData={animationData}
        rendererSettings={{
          preserveAspectRatio: "xMidYMid slice",
        }}
        height={100}
        width={100}
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
        }}
      />

      <CloseButton>
        <RxCross2 />
      </CloseButton>
      <IconImage src={RewardIcon} alt="reward" />
      <SuccessText>CONGRATULATIONS!!</SuccessText>
      <MessageHeading>{message}</MessageHeading>
    </SuccessBoxContainer>
  );
};

export default PointCalculationSuccessBox;
