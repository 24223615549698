import React, { useState, useMemo } from "react";

interface PathwayContextParams {
  pathway: any;
  setPathway: (state: any) => void;
}

const PathwayContext = React.createContext<PathwayContextParams | undefined>(
  undefined
);

PathwayContext.displayName = "PathwayContext";

function PathwayProvider(props: any) {
  const [pathway, setPathway] = useState<string>("");

  const value = useMemo(() => {
    return {
      pathway,
      setPathway,
    };
  }, [pathway]);

  return <PathwayContext.Provider value={value} {...props} />;
}

function usePathwayContext() {
  const context = React.useContext(PathwayContext);

  if (context === undefined) {
    throw new Error(`usePathwayContext must be used within a PathwayProvider`);
  }

  return context;
}

export { PathwayProvider, usePathwayContext };
