import styled from "@emotion/styled/macro";

const BadgeListWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
`;

const BadgeIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const PopoverBadgeListWrapper = styled.div`
  padding: 14px;
  width: 10rem;
  display: flex;
  flex-wrap: wrap;
`;

export { BadgeListWrapper, BadgeIcon, PopoverBadgeListWrapper };
