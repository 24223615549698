import { useMutation, useQueryClient } from "react-query";

//Hooks imports
import { useViewCourseDeeplink } from "hooks";
import { useSelfEnrollCourse } from "hooks/useSelfEnrollCourse";

//Context imports
import { useVleTokenContext } from "context";

//Utils imports
import { client } from "utils/api-client";

function useUpdateRecentComponent({
  courseId,
  percentage,
  setShowUploader,
  setComponentDetailsType,
  isContinueToNextComponent,
}: any) {
  const { vleToken } = useVleTokenContext();

  const { viewCourseDeeplink } = useViewCourseDeeplink();

  const { setEnrollLoader, mutate: enrollCourse } =
    useSelfEnrollCourse(courseId);

  const queryClient = useQueryClient();

  const updateRecentComponent = (data: any) => {
    return client({
      method: "post",
      endpoint: "api/learning-pathway/recent-accessed-component",
      optional: {
        data: data,
        token: vleToken,
      },
    });
  };

  const { mutate, isLoading } = useMutation(updateRecentComponent, {
    onSuccess: (data: any) => {
      if (percentage === "0" && !isContinueToNextComponent) {
        enrollCourse(courseId);
      } else if (isContinueToNextComponent && setShowUploader) {
        queryClient.invalidateQueries("recentCourse");
        queryClient.invalidateQueries("pathwayDetails");

        queryClient.removeQueries("recentCourse");
        queryClient.removeQueries("pathwayDetails");

        setShowUploader("");
      } else {
        viewCourseDeeplink(courseId);
      }

      // Notification({
      // 	message: message ? message : "Recent course updated Successfully",
      // 	type: "success",
      // 	title: "Success",
      // });
    },

    onError: (error: any) => {
      console.log({ error });

      // Notification({
      // 	message: errorMsg
      // 		? errorMsg
      // 		: "Unable to update recent course, please try again later",
      // 	type: "warning",
      // 	title: "Warning",
      // });
    },
  });

  return { mutate, isLoading: isLoading };
}

export { useUpdateRecentComponent };
