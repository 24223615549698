import { useEffect, useState } from "react";

// Third party imports
import { useMutation, useQueryClient } from "react-query";

// Util imports
import { client } from "utils/api-client";
import { Notification } from "utils/notification";

// Context imports
import { useVleTokenContext } from "context";
import { useViewCourseDeeplink } from "hooks";

import showGamificationMessage from "utils/showGamificationMessage";

function useSelfEnrollCourse(id: string) {
  const { viewCourseDeeplink } = useViewCourseDeeplink();
  const [enrollLoader, setEnrollLoader] = useState(false);

  const queryClient = useQueryClient();

  const { vleToken } = useVleTokenContext();

  const enrollMeOnCourse = (courseId: string) => {
    const apiBody = {
      course_id: courseId,
    };

    return client({
      method: "post",
      endpoint: "api/akademy/self-enroll-student",
      optional: {
        data: apiBody,
        token: vleToken,
      },
    });
  };

  const { mutate, isLoading, isError, error } = useMutation(enrollMeOnCourse, {
    onSuccess: (data) => {
      showGamificationMessage({ gamification: data?.data?.data?.gamification });
      // queryClient.invalidateQueries("courseDetails");
      // queryClient.invalidateQueries("courseList");

      // queryClient.invalidateQueries("mykademyLearnerCourses");
      // queryClient.invalidateQueries("pathwayDetails");
      // queryClient.invalidateQueries("learnerCourseList");

      // queryClient.removeQueries("courseDetails");
      // queryClient.removeQueries("mykademyLearnerCourses");
      // queryClient.removeQueries("pathwayDetails");
      // queryClient.removeQueries("learnerCourseList");
      // queryClient.removeQueries("courseList");

      setTimeout(() => {
        viewCourseDeeplink(id);
        // setEnrollLoader(false);
      }, 1000);

      Notification({
        message: "Course Enrolled Successfully",
        type: "success",
        title: "Success",
      });
    },
  });

  useEffect(() => {
    if (isError) {
      setEnrollLoader(false);

      Notification({
        message: "Unable to enroll course, please try again later",
        type: "warning",
        title: "Warning",
      });
    }
  }, [error, id, isError]);

  return {
    mutate,
    isLoading,
    enrollLoader,
    setEnrollLoader,
  };
}

export { useSelfEnrollCourse };
