import { useVleTokenContext } from "context";
import { useState, useEffect } from "react";

// Third party imports
import { useQuery } from "react-query";

// Util imports
import { client } from "utils/api-client";
import { Notification } from "utils/notification";

function useGetLearningPathwayCourses() {
	const [pathwayData, setPathwayData] = useState<any>(null);
	const { vleToken } = useVleTokenContext();

	const getPathwayData = () => {
		return client({
			method: "get",
			endpoint: "api/learning-pathway/courses",
			optional: {
				token: vleToken,
				params: {
					search_keyword: "",
					course_status: 1,
					status: 1,
				},
			},
		});
	};

	const { data, error, isLoading, isSuccess, isError } = useQuery(
		"pathwayData",
		getPathwayData
	);

	useEffect(() => {
		if (isSuccess) {
			setPathwayData(data?.data?.data);
		}
	}, [data, isSuccess]);

	useEffect(() => {
		if (isError) {
			Notification({
				message: "Unable to get pathway data, please try again later",
				type: "warning",
				title: "Warning",
			});
		}
	}, [error, isError]);

	return {
		pathwayData,
		isLoading,
	};
}

export { useGetLearningPathwayCourses };
