/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { Fragment, useEffect } from "react";

//Hook imports
import { useAddOrRemoveCourse } from "hooks";

//Context imports
import { useCartStateContext, useMarketPlaceFilterContext } from "context";

//Style imports
import { Button } from "styles/components.styles";

// Image imports
import { FiCheck } from "react-icons/fi";
import { HiOutlinePlus } from "react-icons/hi";

const ActionButton = ({
  showActionButtonLabel,
  course,
  enablePayment,
}: any) => {
  const { addCourse, removeCourse, isSelected, setCurrentCourse } =
    useAddOrRemoveCourse();
  const { courseProviderValue } = useMarketPlaceFilterContext();

  const { addToCart, removeFromCart, carts } = useCartStateContext();
  const isAddedToCart = carts?.find((cart: any) => cart.id === course?.id);

  useEffect(() => {
    setCurrentCourse(course);
  }, [course, setCurrentCourse]);

  return (
    <Button
      variant={
        enablePayment
          ? course?.is_free === "1"
            ? isSelected
              ? "green"
              : "purple"
            : !!isAddedToCart
            ? "green"
            : "purple"
          : isSelected
          ? "green"
          : "purple"
      }
      fontweight="600"
      padding={showActionButtonLabel ? "5px 10px" : "9px"}
      onClick={() => {
        if (enablePayment) {
          if (course?.is_free === "1") {
            if (isSelected) {
              removeCourse({ course });
            } else {
              addCourse({ course });
            }
          } else {
            if (isAddedToCart) {
              removeFromCart(course?.id);
            } else {
              addToCart({ ...course, courseProvider: courseProviderValue });
              localStorage.setItem("clientMarketPlaceCart", "1");
            }
          }
        } else {
          if (isSelected) {
            removeCourse({ course });
          } else {
            addCourse({ course });
          }
        }
      }}
    >
      {enablePayment ? (
        course?.is_free === "1" ? (
          isSelected ? (
            <Fragment>
              <FiCheck
                style={{
                  width: "13px",
                  height: "13px",
                  marginRight: showActionButtonLabel ? "6px" : 0,
                }}
              />
              {showActionButtonLabel && "Added"}
            </Fragment>
          ) : (
            <Fragment>
              <HiOutlinePlus
                style={{
                  width: "13px",
                  height: "13px",
                  marginRight: showActionButtonLabel ? "6px" : 0,
                }}
              />
              {showActionButtonLabel && "Add"}
            </Fragment>
          )
        ) : !!isAddedToCart ? (
          <Fragment>
            <FiCheck
              style={{ width: "13px", height: "13px", marginRight: "6px" }}
            />

            {showActionButtonLabel && "In Cart"}
          </Fragment>
        ) : (
          <Fragment>
            <HiOutlinePlus
              style={{
                width: "13px",
                height: "13px",
                marginRight: showActionButtonLabel ? "8px" : 0,
              }}
            />
            {showActionButtonLabel && <span>Add To Cart</span>}
          </Fragment>
        )
      ) : isSelected ? (
        <Fragment>
          <FiCheck
            style={{
              width: "13px",
              height: "13px",
              marginRight: showActionButtonLabel ? "6px" : 0,
            }}
          />
          {showActionButtonLabel && "Added"}
        </Fragment>
      ) : (
        <Fragment>
          <HiOutlinePlus
            style={{
              width: "13px",
              height: "13px",
              marginRight: showActionButtonLabel ? "8px" : 0,
            }}
          />

          {showActionButtonLabel && "Add"}
        </Fragment>
      )}
    </Button>
  );
};

export default ActionButton;
