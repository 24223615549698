/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React from "react";
import * as mq from "styles/media-queries";

import { Link as RouterLink, useRouteMatch } from "react-router-dom";

function SidebarNavLink(props: any) {
  const match = useRouteMatch(props.to);

  return (
    <RouterLink
      css={[
        {
          display: "flex",
          alignItems: "center",
          margin: "4px 0 4px 0",
          borderRadius: "4px",
          padding: props.padding ? props.padding : "8px 30px",
          color: "#000",
          fontSize: props.fontsize ? props.fontsize : "14px",

          [mq.smallPhone]: {
            justifyContent: "center",
          },

          [mq.desktop]: {
            justifyContent: "flex-start",
          },

          ":hover": {
            color: "#9b9b9b",
          },
        },
        match?.isExact
          ? {
              fontWeight: "bold",
              background: "#fff",
              ":hover": {
                color: "#9b9b9b",
              },
            }
          : null,
      ]}
      {...props}
    >
      <div style={{ width: "200px" }}>{props?.children}</div>
    </RouterLink>
  );
}

export default SidebarNavLink;
