/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { Fragment } from "react";

import { useHistory } from "react-router-dom";

import { Iframe } from "components";

import { Button } from "styles/components.styles";

import styled from "@emotion/styled/macro";

import ArrowLeftIcon from "assets/images/common/arrow-left.svg";
import { useGetOrgData, useIsMobileView } from "hooks";

interface IframeScreenProps {
  src: string;
  title: string;
  routeTo?: string;
  isLoading?: boolean;
}

const Wrapper = styled.div`
  padding: 10px 20px;
  height: 50px;
  background: #ebebeb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
`;

const IframeScreen = ({
  src,
  title,
  routeTo,
  isLoading,
}: IframeScreenProps) => {
  const history = useHistory();

  const { isMobileView } = useIsMobileView();

  const { orgData } = useGetOrgData();

  function backRouteHandler() {
    if (routeTo) {
      history.push(routeTo);
    } else {
      // refresh the page for them
      // @ts-ignore
      window.location.assign(window.location);
    }
  }

  return (
    <Fragment>
      <Wrapper>
        <Button variant="transparent" padding="0px" onClick={backRouteHandler}>
          <img
            css={{ marginRight: "8px" }}
            src={ArrowLeftIcon}
            alt="arrow left icon"
          />
          Back
        </Button>
        <div>
          <span
            style={{
              display: isMobileView ? "none" : "inline-block",
            }}
          >
            Need Help? Contact:
          </span>
          <a href={`mailto:${orgData?.org?.content_support_email}`}>
            {!isMobileView ? orgData?.org?.content_support_email : "Help"}
          </a>
        </div>
      </Wrapper>
      <Iframe
        src={src}
        title={title}
        height="calc(100vh - 50px)"
        isLoading={isLoading}
      />
    </Fragment>
  );
};

export default IframeScreen;
