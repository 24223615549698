const formattedDateAndTime = (date: string) => {
  let currentDate = new Date(`${date}`);

  let month = currentDate.toLocaleString("default", { month: "short" });
  let day = ("0" + currentDate.getDate()).slice(-2);
  let year = currentDate.getFullYear().toString();

  let hour = currentDate.getHours();
  let minutes = ("0" + currentDate.getMinutes()).slice(-2);
  let ampm = hour >= 12 ? "pm" : "am";
  hour = hour % 12;
  hour = hour ? hour : 12;

  let dateTimeString = `${month} ${day} ${year}, ${hour}:${minutes} ${ampm}`;

  return dateTimeString;
};

export default formattedDateAndTime;
