// Function to remove duplicates and get unique values
const getUniqueValues = (arr: any, key: any) => {
  const uniqueMap = new Map();

  arr.forEach((obj: any) => {
    const value = obj[key];
    uniqueMap.set(value, obj);
  });

  return Array.from(uniqueMap.values());
};

export { getUniqueValues };
