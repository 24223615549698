// Third party imports
import { useMutation, useQueryClient } from "react-query";

// Util imports
import { client } from "utils/api-client";
import { Notification } from "utils/notification";

// Context imports
import { usePathwayTokenContext, useVleTokenContext } from "context";

function useClaimBadgeFromEvent() {
  const queryClient = useQueryClient();

  const { pathwayToken } = usePathwayTokenContext();

  const { vleToken } = useVleTokenContext();

  const claimBadgeFromEvent = (data: any) => {
    let apiBody = {
      ...data,
      token: pathwayToken,
    } as const;

    return client({
      method: "post",
      endpoint: "api/reputation/badge-from-event",
      optional: {
        data: apiBody,
        token: vleToken,
      },
    });
  };

  const { isLoading, mutate, mutateAsync } = useMutation(claimBadgeFromEvent, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("activityHistory");
      queryClient.removeQueries("activityHistory");
      queryClient.invalidateQueries("memberGamificationDetails");
      queryClient.removeQueries("memberGamificationDetails");
      queryClient.invalidateQueries("leaderboardData");
      queryClient.removeQueries("leaderboardData");
    },
    onError(error: any, variables, context) {
      const { message } = error?.response?.data;
      Notification({
        message,
        type: "warning",
        title: "Warning",
      });
    },
  });

  return {
    mutate,
    isLoading,
    mutateAsync,
  };
}

export { useClaimBadgeFromEvent };
