/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { Fragment } from "react";

const ProgressBar = ({ progress }: any) => {
	return (
		<Fragment>
			<div
				css={{
					backgroundColor: "#f2f2f2",
					height: "100% !important",
				}}
				className="progress"
			>
				<div
					className="progress-bar progress-bar-striped bg-success"
					role="progressbar"
					css={{ width: `${progress}%`, fontSize: "12px" }}
				>
					{progress}%
				</div>
			</div>
		</Fragment>
	);
};

export default ProgressBar;
