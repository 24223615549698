import FallBackUi from "components/fallBackUi";
import React, { FC, ReactNode } from "react";
import { ErrorBoundary as ErrorLimit } from "react-error-boundary";

interface IErrorBoundaryProps {
  children: ReactNode;
  height?: string;
  background?: string;
}

const ErrorBoundary: FC<IErrorBoundaryProps> = ({
  children,
  background,
  height,
}) => {
  return (
    <ErrorLimit
      fallbackRender={(props) => (
        <FallBackUi {...props} background={background} height={height} />
      )}
    >
      {children}
    </ErrorLimit>
  );
};

export default ErrorBoundary;
