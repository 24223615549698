import { useGamificationContext } from "context";
import { useState, useEffect } from "react";

function useGetAdminToolsData({ videoClassroomLabelData }: any) {
  const [vleMentor, setVleMentor] = useState<any>([]);
  const [vleAdmin, setVleAdmin] = useState<any>([]);
  const [vleTutorLegacy, setVleTutorLegacy] = useState<any>([]);
  const [vleBranchAdmin, setVleBranchAdmin] = useState<any>([]);
  const [vleContentEditor, setVleContentEditor] = useState<any>([]);
  const [pathwayAdmin, setPathwayAdmin] = useState<any>([]);
  const [reportAdmin, setReportAdmin] = useState<any>([]);
  const [reportCe, setReportCe] = useState<any>([]);
  const { badgeLabel } = useGamificationContext();

  useEffect(() => {
    const videoClassroomText = videoClassroomLabelData?.find(
      (label: any) => label.key === "sidebarMenuLabel"
    );

    const vleMentor = [
      {
        title: "Learners",
        icon: "LearnersIcon",
        route: "/mykademyAdmin/learners",
      },
      {
        title: videoClassroomText?.label,
        icon: "VideoIcon",
        route: "/mykademyAdmin/videoClassroom",
      },
    ];

    const vleAdmin = [
      {
        title: "Learners",
        icon: "LearnersIcon",
        route: "/mykademyAdmin/learners",
      },
      {
        title: videoClassroomText?.label,
        icon: "VideoIcon",
        route: "/mykademyAdmin/videoClassroom",
      },
      {
        title: "Course List",
        icon: "CourseListIcon",
        route: "/mykademyAdmin/courseList",
      },
      {
        title: "Facilitator",
        icon: "LearnersIcon",
        route: "/mykademyAdmin/facilitator",
      },
      {
        title: "Branches",
        icon: "BranchIcon",
        route: "/mykademyAdmin/branches",
      },
      {
        title: "Batches",
        icon: "BatchesIcon",
        route: "/mykademyAdmin/batches",
      },
      {
        title: "Bundles",
        icon: "BundlesIcon",
        route: "/mykademyAdmin/bundles",
      },

      // {
      // 	title: "Tutor",
      // 	icon: "LearnersIcon",
      // 	route: "/mykademyAdmin/adminTutor",
      // },
    ];

    const vleTutorLegacy = [
      {
        title: "Learners",
        icon: "LearnersIcon",
        route: "/mykademyAdmin/learners",
      },
      {
        title: videoClassroomText?.label,
        icon: "VideoIcon",
        route: "/mykademyAdmin/videoClassroom",
      },
      {
        title: "Course List",
        icon: "CourseListIcon",
        route: "/mykademyAdmin/courseList",
      },
    ];

    const vleBranchAdmin = [
      {
        title: "Learners",
        icon: "LearnersIcon",
        route: "/mykademyAdmin/learners",
      },
      {
        title: videoClassroomText?.label,
        icon: "VideoIcon",
        route: "/mykademyAdmin/videoClassroom",
      },
      {
        title: "Course List",
        icon: "CourseListIcon",
        route: "/mykademyAdmin/courseList",
      },

      {
        title: "Batches",
        icon: "BatchesIcon",
        route: "/mykademyAdmin/batches",
      },
      {
        title: "Bundles",
        icon: "BundlesIcon",
        route: "/mykademyAdmin/bundles",
      },
    ];

    const vleContentEditor = [
      {
        title: "Course List",
        icon: "CourseListIcon",
        route: "/mykademyAdmin/courseList",
      },
    ];

    const pathwayAdmin = [
      {
        title: "Pathway",
        route: "/mykademyAdmin/pathway",
        icon: "PathwayIcon",
      },
      {
        title: "Enrollments",
        route: "/mykademyAdmin/pathwayEnrollment",
        icon: "EnrollmentIcon",
      },
      {
        title: "Reputation Points",
        route: "/mykademyAdmin/reputationPoints",
        icon: "ReputationIcon",
      },
      {
        title: badgeLabel,
        route: "/mykademyAdmin/badges",
        icon: "BadgeIcon",
      },
    ];

    const reportAdmin = [
      {
        title: "Power Bi",
        icon: "PowerBiIcon",
        route: "/mykademyAdmin/powerBi",
      },
      {
        title: "Course Report",
        icon: "ReportIcon",
        route: "/mykademyAdmin/courseReport",
      },
      {
        title: "Enrollment Report",
        icon: "EnrollIcon",
        route: "/mykademyAdmin/consolidatedEndrollmentReport",
      },
    ];

    const reportCe = [
      {
        title: "Course Report",
        icon: "ReportIcon",
        route: "/mykademyAdmin/courseReport",
      },
    ];

    setVleMentor(vleMentor);
    setVleAdmin(vleAdmin);
    setVleTutorLegacy(vleTutorLegacy);
    setVleBranchAdmin(vleBranchAdmin);
    setVleContentEditor(vleContentEditor);
    setPathwayAdmin(pathwayAdmin);
    setReportAdmin(reportAdmin);
    setReportCe(reportCe);
  }, [badgeLabel, videoClassroomLabelData]);

  return {
    reportCe,
    vleAdmin,
    vleMentor,
    reportAdmin,
    pathwayAdmin,
    vleBranchAdmin,
    vleTutorLegacy,
    vleContentEditor,
  };
}

export { useGetAdminToolsData };
