/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import { FileUploader } from "react-drag-drop-files";

import DropZoneChildren from "./dropZoneChildren";

import { DropzoneWrapper } from "./dropzone.styles";

const Dropzone = ({
  file,
  setFile,
  errorMsg,
  isLoading,
  fileTypes,
  setErrorMsg,
}: any) => {
  const handleChange = (file: any) => {
    setFile(file);

    if (
      errorMsg === "Please select a document" ||
      errorMsg === "Document too large, allowed only upto 15 MB"
    ) {
      setErrorMsg("");
    }
  };

  return (
    <DropzoneWrapper
      isSizeError={errorMsg === "Document too large, allowed only upto 15 MB"}
    >
      <FileUploader
        name="file"
        label="Drag & Drop, or browse your file (Max size of 15 MB)"
        hoverTitle="hover"
        maxSize={15}
        classes="drop_area drop_zone"
        multiple={false}
        types={fileTypes}
        disabled={isLoading}
        handleChange={handleChange}
        onSizeError={(file: any) =>
          setErrorMsg("Document too large, allowed only upto 15 MB")
        }
        onTypeError={(file: any) =>
          setErrorMsg(
            "Document type not allowed, please upload the allowed document types"
          )
        }
        children={
          <DropZoneChildren
            file={file}
            errorMsg={errorMsg}
            fileTypes={fileTypes}
          />
        }
      />
      <p
        css={{
          marginTop: "8px",
          marginBottom: "6px",
          lineBreak: "anywhere",
        }}
      >
        {file ? (
          <div>
            <span css={{ fontWeight: "bold" }}>File name: </span>
            <span>{file.name}</span>
          </div>
        ) : (
          "No files uploaded yet"
        )}
      </p>
    </DropzoneWrapper>
  );
};

export default Dropzone;
