/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, keyframes } from "@emotion/react";

// Third party imports
import styled from "@emotion/styled/macro";
import ContentLoader from "react-content-loader";

import {
	DialogContent as ReachDialog,
	DialogOverlay as OverlayDialog,
} from "@reach/dialog";

import * as colors from "styles/colors";
import * as mq from "styles/media-queries";

import { FaSpinner } from "react-icons/fa";

interface ButtonProps {
	variant?: string;
	fontsize?: string;
	fontweight?: any;
	height?: string;
	borderradius?: string;
	padding?: string;
	disabled?: boolean;
	width?: string;
	hoverColor?: string;
	hoverBgColor?: string;
}

const spin = keyframes({
	"0%": { transform: "rotate(0deg)" },
	"100%": { transform: "rotate(360deg)" },
});

interface SpinnerProps {
	isLoading?: boolean;
	fontsize?: string | undefined;
}

const Spinner = styled(FaSpinner)<SpinnerProps>(
	({ isLoading, fontsize = "2em" }) => ({
		fontSize: fontsize,
		animation: `${spin} 1s linear infinite`,
		width: "40px",
		height: "40px",
	})
);

const TransparentSpinnerWrapper = styled.div`
	font-size: 5em;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.2);
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 9999;
	top: 0;
	left: 0;
`;

const TransparentSpinner = () => {
	return (
		<TransparentSpinnerWrapper>
			<Spinner />
		</TransparentSpinnerWrapper>
	);
};

const CenteredSpinner = () => {
	return (
		<div css={{ textAlign: "center" }}>
			<Spinner />
		</div>
	);
};

const FullPageWrapper = styled.div`
	font-size: 5em;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: white;
	z-index: 99999999;
`;

const FullPageSpinner = () => (
	<FullPageWrapper>
		<Spinner />
	</FullPageWrapper>
);

interface CustomHeightSpinnerProps {
	height: string;
	display?: string;
}

const CustomHeightSpinner = ({
	height,
	display = "flex",
}: CustomHeightSpinnerProps) => (
	<div
		css={{
			display: display,
			height: height,
			fontSize: "5em",
			justifyContent: "center",
			alignItems: "center",
		}}
	>
		<Spinner />
	</div>
);

const SkeletonLoader = (props: any) => {
	return (
		<ContentLoader
			speed={1}
			width={300}
			height={150}
			viewBox="0 0 300 150"
			backgroundColor="#f5f5f5"
			foregroundColor="#dbdbdb"
			{...props}
		>
			<rect x="51" y="14" rx="3" ry="3" width="176" height="12" />
			<circle cx="20" cy="20" r="20" />
			<circle cx="21" cy="70" r="20" />
			<rect x="51" y="64" rx="0" ry="0" width="176" height="13" />
			<circle cx="21" cy="120" r="19" />
			<rect x="52" y="116" rx="0" ry="0" width="176" height="12" />
		</ContentLoader>
	);
};

const buttonVariants: any = {
	disabled: {
		color: colors.gray80,
		background: colors.gray20,
		boxShadow:
			"0px 0.3px 0.9px rgb(0 0 0 / 32%), 0px 1.6px 3.6px rgb(0 0 0 / 28%)",
	},
	success: {
		color: colors.black1,
		background: "#00ffa6",
		boxShadow:
			"0px 0.3px 0.9px rgb(0 0 0 / 32%), 0px 1.6px 3.6px rgb(0 0 0 / 28%)",
	},
	light: {
		color: colors.base,
		padding: "8px 20px 9px",
		border: `1px solid ${colors.base}`,
		background: "transparent",
	},
	dark: {
		color: colors.black,
		border: `1px solid ${colors.gray1}`,
		boxShadow:
			"0 1.6px 3.6px 0 rgb(0 0 0 / 11%), 0 0.3px 0.9px 0 rgb(0 0 0 / 7%)",
		background: "transparent",
	},
	successReverse: {
		color: "rgb(0, 255, 166)",
		margin: "10px 0 0",
		background: "transparent",
		paddingLeft: 0,
	},
	purple: {
		background: colors.purple,
		color: "#fff",
		boxShadow:
			"0 1.6px 3.6px 0 rgb(0 0 0 / 11%), 0 0.3px 0.9px 0 rgb(0 0 0 / 7%)",
	},
	white: {
		background: colors.base,
		color: "#242424",
		borderColor: "#c7c7c7",
		border: "1px solid #c7c7c7",
		boxShadow:
			"0 1.6px 3.6px 0 rgb(0 0 0 / 11%), 0 0.3px 0.9px 0 rgb(0 0 0 / 7%)",
	},
	green: {
		background: "#13a10e",
		color: "#fff",
		borderColor: "#13a10e",
	},

	greenText: {
		color: "#00ffa6",
		background: "transparent",
		border: `1px solid ${colors.base}`,
		padding: "8px 20px 9px",
	},

	purpleText: {
		color: "#6264a7",
		background: "transparent",
	},

	primary: {
		color: "white",
		background: "#468be8",
		padding: "8px 20px",
	},

	transparent: {
		background: "transparent",
	},

	lightPurpleText: {
		color: "#7f80b7",
		background: "transparent",
		padding: 0,
	},

	blackText: {
		color: "#212529",
		background: "transparent",
	},

	greyText: {
		color: "#9b9b9b",
		background: "transparent",
	},

	blueBg: {
		padding: "8px 13px",
		border: "0",
		backgroundColor: " #468be8",
		color: "white",
		fontWeight: "500",
	},

	microsoftBg: {
		padding: "8px 13px",
		border: "0",
		backgroundColor: "#6264a7",
		color: "white",
		fontWeight: "500",
	},

	chineseBlue: {
		background: colors.chineseBlue,
		boxShadow:
			"0 1.6px 3.6px 0 rgb(0 0 0 / 11%), 0 0.3px 0.9px 0 rgb(0 0 0 / 7%)",
	},
	ghostWhite: {
		background: colors.ghostWhite,
		color: "#1B1C21",
		fontWeight: "600",
	},
	charcoalGrey: {
		color: "#fff",
		background: "#4a4a4a",
	},
	danger: {
		color: "#fff",
		background: "#dc3545",
		borderColor: "#dc3545",
	},
	blueBorder: {
		border: "2px solid #6264a7",
		color: "#6F75BC",
		boxShadow:
			"0 1.6px 3.6px 0 rgb(0 0 0 / 11%), 0 0.3px 0.9px 0 rgb(0 0 0 / 7%)",
		background: "transparent",
	},
};

const Button = styled.button<ButtonProps>(
	({
		width,
		height,
		padding,
		fontsize,
		disabled,
		fontweight,
		hoverColor,
		hoverBgColor,
		borderradius,
	}: ButtonProps) => ({
		fontSize: fontsize ? fontsize : "0.9rem",
		fontWeight: fontweight ? fontweight : "normal",
		height: height ? height : "auto",
		padding: padding ? padding : "7px 12px",
		borderRadius: borderradius ? borderradius : "4px",
		textTransform: "capitalize",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		border: "none",
		outline: "none",
		cursor: disabled ? "not-allowed" : "",
		width: width ? width : "",

		":hover": {
			color: hoverColor ? hoverColor : "",
			background: hoverBgColor ? hoverBgColor : "",
		},

		":disabled": {
			color: "#666666",
			background: "#cccccc",
		},
	}),
	({ variant = "success" }: ButtonProps) => buttonVariants[variant]
);

const CircleButton = styled.button((props) => ({
	borderRadius: "30px",
	padding: "0",
	width: "40px",
	height: "40px",
	lineHeight: "1",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	background: colors.base,
	color: colors.text,
	border: `1px solid ${colors.gray2}`,
	cursor: "pointer",
	outline: "none",
	// position: "absolute",
}));

const DialogOverlay = styled(OverlayDialog)`
	z-index: 99998 !important;

	${mq.smallPhone} {
		overflow-y: scroll;
	}

	${mq.tablet} {
		overflow: hidden;
	}

	${mq.tabletWide} {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

interface DialogProps {
	maxwidth: string;
	height: string;
}

const Dialog = styled(ReachDialog)`
	max-width: ${(props: DialogProps) =>
		props.maxwidth ? props.maxwidth : "500px"};
	border-radius: 3px;
	padding-bottom: 2em;
	box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.2);
	margin: 0;
	height: ${(props: DialogProps) => (props.height ? props.height : "auto")};
	overflow-y: auto;

	::-webkit-scrollbar-track {
		border-radius: 4px;
	}

	::-webkit-scrollbar {
		width: 8px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: hsl(238 26% 47% / 1);
		border: 1px solid #f5f5f5;
	}

	${mq.smallPhone} {
		width: 95%;
		margin: 10vh auto;
	}
`;

const BoxShadow = styled.div`
	padding: 20px;
	border-radius: 4px;
	box-shadow: 0 0 2px 0 rgb(0 0 0 / 12%), 0 2px 4px 0 rgb(0 0 0 / 14%);
	border: solid 0 #e73550;
	background-color: #fff;
	overflow: hidden;
	margin-bottom: 20px;
`;

const CenteredElement = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export {
	Dialog,
	Button,
	Spinner,
	BoxShadow,
	CircleButton,
	DialogOverlay,
	SkeletonLoader,
	CenteredSpinner,
	FullPageSpinner,
	CenteredElement,
	TransparentSpinner,
	CustomHeightSpinner,
};
