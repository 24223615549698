/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import { useState, useEffect, lazy, Suspense } from "react";

import { QueryCache } from "react-query";
import * as microsoftTeams from "@microsoft/teams-js";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";

import { PrivateRoute } from "components";

import ImportCourse from "screens/coursesTab/importCourse";

import { localStorageKeys, useLogout, useLocalStorageState } from "hooks";

import { FullPageSpinner } from "styles/components.styles";
// import { CertificateQrCodePreview } from "screens";

const Login = lazy(() => import("screens/login"));
const HomeTab = lazy(() => import("screens/homeTab"));
const Privacy = lazy(() => import("screens/privacy"));
const Register = lazy(() => import("screens/register"));
const Mykademy = lazy(() => import("screens/mykademy"));
const Settings = lazy(() => import("screens/settings"));
const AddMember = lazy(() => import("screens/addMember"));
const TermsOfUse = lazy(() => import("screens/termsOfUse"));
const Onboarding = lazy(() => import("screens/onboarding"));
const AddLearner = lazy(() => import("screens/addLearner"));
const CalendarTab = lazy(() => import("screens/calendarTab"));
const TalkToSales = lazy(() => import("screens/talkToSales"));
const AddLearning = lazy(() => import("screens/addLearning"));
const AiContentPro = lazy(() => import("screens/aiContentPro"));
const AddLearnerSso = lazy(() => import("screens/addLearnerSso"));
const GetStartedTab = lazy(() => import("screens/getStartedTab"));
const MykademyAdmin = lazy(() => import("screens/mykademyAdmin"));
const PortfolioList = lazy(() => import("screens/portfolioList"));
const Authentication = lazy(() => import("screens/authentication"));
const RegisterForSso = lazy(() => import("screens/registerForSso"));
const CoursesTab = lazy(() => import("screens/coursesTab"));
const MyAchievements = lazy(() => import("screens/myAchievements"));
const CertificateQrCodePreview = lazy(
  () =>
    import(
      "screens/coursesTab/myPathways/learningPathway/certificateQrCodePreview"
    )
);

// const MembershipPlans = lazy(() => import("screens/membershipPlans"));
const GetAccountReady = lazy(() => import("screens/getAccountReady"));
const PortfolioCourses = lazy(() => import("screens/portfolioCourses"));
const LearningProvider = lazy(() => import("screens/learningProvider"));
const RegisterComplete = lazy(() => import("screens/registerComplete"));
const EnterCardDetails = lazy(() => import("screens/enterCardDetails"));
const SsoAuthentication = lazy(() => import("screens/ssoAuthentication"));
const CourseProviderLinkStatusToStripe = lazy(
  () => import("screens/courseProviderLinkStatusToStripe")
);

const AddVleMemberRegistration = lazy(
  () => import("screens/addVleMemberRegistration")
);

const MemberRegisterComplete = lazy(
  () => import("screens/memberRegisterComplete")
);

export const queryCache = new QueryCache({
  onError: (error) => {
    console.log(error);
  },
  onSuccess: (data) => {
    console.log(data);
  },
});

// Initialize the Microsoft Teams SDK
microsoftTeams.initialize();

function parseJwt(token: string) {
  var base64Url = token.split(".")[1];

  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");

  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

function App() {
  const [loader, setLoader] = useState(false);

  const { setLogout, logoutLoader } = useLogout();

  const [msOid, setMsOid] = useLocalStorageState({
    key: localStorageKeys.msOid,
  });

  useEffect(() => {
    if (process.env.REACT_APP_HIDE_LOADER) {
      setLoader(false);
    }

    let authTokenRequestOptions = {
      successCallback: (result: any) => {
        const parsedJwt = parseJwt(result);

        if (msOid) {
          if (msOid !== parsedJwt.oid) {
            setLogout(true);
          }
        } else {
          setMsOid(parsedJwt.oid);
        }

        setLoader(false);
      },

      failureCallback: (error: any) => {
        console.log("failure call back", error);

        setLoader(false);
      },
    };

    microsoftTeams.authentication.getAuthToken(authTokenRequestOptions);
  }, [msOid, setLogout, setMsOid]);

  return (
    <div className="App">
      <Router>
        <Switch>
          <Suspense fallback={<FullPageSpinner />}>
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/termsofuse" component={TermsOfUse} />

            {/* Teams tabs routes */}
            <Route exact path="/" component={HomeTab} />

            <Route exact path="/homeTab" component={HomeTab} />
            <Route exact path="/dashboard" component={HomeTab} />

            <Route exact path="/calendarTab" component={CalendarTab} />
            <Route exact path="/calendar-tab" component={CalendarTab} />

            <Route exact path="/getStartedTab" component={GetStartedTab} />
            <Route exact path="/tab" component={GetStartedTab} />

            <Route path="/coursesTab" component={CoursesTab} />
            <Route path="/course-list" component={CoursesTab} />

            <Route path="/market-place" component={CoursesTab} />

            <Route exact path="/aiContentPro" component={AiContentPro} />

            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={Register} />
            <Route exact path="/onboarding" component={Onboarding} />
            <Route exact path="/addLearner" component={AddLearner} />
            <Route exact path="/talkToSales" component={TalkToSales} />

            <Route
              exact
              path="/registerComplete"
              component={RegisterComplete}
            />

            <Route
              exact
              path="/learningProvider"
              component={LearningProvider}
            />

            <Route exact path="/addVleMember" component={Mykademy} />

            <Route
              exact
              path="/portfolioCourses/:id"
              component={PortfolioCourses}
            />

            <Route exact path="/portfolioList" component={PortfolioList} />

            <Route
              exact
              path="/account"
              component={CourseProviderLinkStatusToStripe}
            />

            <Route exact path={`/importCourse`}>
              <ImportCourse />
            </Route>

            <Route exact path={`/myAchievements`}>
              <MyAchievements />
            </Route>

            {/* <Route exact path={`/membershipPlans`}>
									<MembershipPlans />
								</Route> */}

            <Route exact path={`/enterCardDetails`}>
              <EnterCardDetails />
            </Route>

            {/* Mykademy admin routes */}
            <PrivateRoute path={`/mykademyAdmin`}>
              <MykademyAdmin />
            </PrivateRoute>

            <PrivateRoute path={`/reports`}>
              <MykademyAdmin />
            </PrivateRoute>

            <PrivateRoute path={`/learningPathway`}>
              <MykademyAdmin />
            </PrivateRoute>

            <Route exact path={`/registerForSso`}>
              <RegisterForSso />
            </Route>

            <Route exact path={`/addLearning`}>
              <AddLearning />
            </Route>

            <Route exact path={`/addMember`}>
              <AddMember />
            </Route>

            <Route
              exact
              path="/authentication/:token"
              component={Authentication}
            />

            {/* <Route
									exact
									path="/check-and-update/:token"
									component={UploadToken}
								/> */}

            {/* sso signup & authentication routes */}
            <Route
              exact
              path="/ssoAuthentication/:token"
              component={SsoAuthentication}
            />

            <Route
              exact
              path="/ssoUserAuthentication/:token"
              component={SsoAuthentication}
            />
            <Route exact path={`/memberRegisterComplete`}>
              <MemberRegisterComplete />
            </Route>

            <Route exact path={`/getAccountReady`}>
              <GetAccountReady />
            </Route>

            <Route exact path={`/addVleMemberRegistration`}>
              {/* <AddVleMemberRegistration /> */}
              <AddLearnerSso />
            </Route>

            <Route exact path={`/addVleMemberSso`}>
              <AddVleMemberRegistration />
              {/* <AddLearnerSso /> */}
            </Route>

            <Route path={`/settings`}>
              <Settings />
            </Route>

            <Route path="/pathway-certificate/preview">
              <CertificateQrCodePreview />
            </Route>
          </Suspense>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
