import { useState, useEffect } from "react";

//ThirdParty imports
import jwt_decode from "jwt-decode";
import { useMutation } from "react-query";

// Context imports
import { usePathwayTokenContext, useVleTokenContext } from "context";
import { client } from "utils/api-client";

function useAuthorizeLearnerPathway() {
  const [existingPathwayToken] = useState(
    JSON.parse(localStorage.getItem("pathwayToken")!)
  );
  const { pathwayToken, setPathwayToken } = usePathwayTokenContext();

  const { vleToken } = useVleTokenContext();

  const getLearnerPathwayAccessToken = (data: any) => {
    return client({
      method: "post",
      endpoint: "api/learning-pathway/auth",
      optional: {
        data,
        token: vleToken,
      },
    });
  };

  const { isLoading, mutateAsync, isSuccess } = useMutation(
    getLearnerPathwayAccessToken
  );

  useEffect(() => {
    if (existingPathwayToken) {
      const { exp }: any = jwt_decode(existingPathwayToken);
      const hasExpired = exp < (new Date().getTime() + 1) / 1000;
      if (hasExpired) {
        mutateAsync({
          token: existingPathwayToken,
        }).then((res) => setPathwayToken(res.data.data));
      }
    } else {
      mutateAsync({
        token: existingPathwayToken,
      }).then((res) => setPathwayToken(res.data.data));
    }
  }, [existingPathwayToken, mutateAsync, setPathwayToken]);

  return { pathwayToken, isLoading, isSuccess };
}

export { useAuthorizeLearnerPathway };
