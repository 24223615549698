import axios from "axios";

const baseURL = process.env.REACT_APP_TEAMS_API_URL;

const mykademyBaseURL =
	process.env.REACT_APP_MYKADEMY_SUBDOMAIN_VALIDATION_APIURL;

const newsFeedBaseURL = process.env.REACT_APP_NEWSFEED_API_URL;

const portfolioURL = process.env.REACT_APP_MYPORTFOLIO_API_URL;

const learningPathwayBaseURL = process.env.REACT_APP_LEARNER_PATHWAY_BASEURL;
// const portfolioURL = "https://api.connectedportfolio.olivegroup.io";

interface ClientProps {
	method: any;
	endpoint: string;
	optional?: {
		data?: any;
		token?: string;
		params?: {};
		headers?: {};
		baseUrl?: string;
		dontStringify?: boolean;
		isFormData?: boolean;
		onUploadProgress?: (progressEvent: ProgressEvent) => {};
	};
}

async function client({
	method,
	endpoint,
	optional: {
		data,
		token,
		params,
		headers: customHeaders,
		baseUrl,
		dontStringify,
		isFormData,
		...customConfig
	} = {},
}: ClientProps) {
	function checkBaseUrl() {
		if (baseUrl === "mykademy") {
			return mykademyBaseURL;
		} else if (baseUrl === "newsFeed") {
			return newsFeedBaseURL;
		} else if (baseUrl === "portfolio") {
			return portfolioURL;
		} else if (baseUrl === "pathway") {
			return learningPathwayBaseURL;
		} else {
			return baseURL;
		}
	}

	let currentBaseUrl = checkBaseUrl();

	const config = {
		method: method.toUpperCase(),
		data: data ? (dontStringify ? data : JSON.stringify(data)) : undefined,
		baseURL: currentBaseUrl,
		url: `/${endpoint}`,
		headers: {
			// Authorization: token ? `Bearer ${token}` : undefined,
			Authorization: token ? token : undefined,
			"Content-Type": data ? "application/json" : undefined,
			...customHeaders,
		},
		params: {
			...params,
		},
		...customConfig,
	};

	try {
		const response = await axios(config);

		return response;
	} catch (error) {
		console.log("catch", error);
		return Promise.reject(error);
	}
}

export { client };
