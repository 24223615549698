/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { Fragment, useState, useEffect } from "react";

// Third party imports
import { useHistory } from "react-router-dom";
import { Container, Row } from "react-bootstrap";

import CourseAdd from "screens/onboarding/courseAdd";
import OnboardingFooter from "screens/onboarding/onboardingFooter";

// Style imports
import { FullPageSpinner, Button } from "styles/components.styles";

import {
  AddContentTitle,
  OnboardingWrapper,
  AddContentTitleWrapper,
} from "screens/onboarding/onboarding.styles";

// Hooks imports
import { useAsync, useGetCpCourses } from "hooks";

// Utils imports
import { client } from "utils/api-client";
import { Notification } from "utils/notification";

// Context imports
import {
  useDomainContext,
  useVleTokenContext,
  useAddedCoursesContext,
  useMarketPlaceFilterContext,
} from "context";

export interface CourseProps {
  access_validity: string;
  b2b_sharable_url: string;
  category: string;
  created_date: string;
  description: string;
  discount: string;
  has_b2b_sale: string;
  id: string;
  image: string;
  imported: string;
  is_featured: string;
  is_free: string;
  price: string;
  short_description: string;
  status: string;
  title: string;
  total_enrolled_users: string;
  total_lectures: string;
  validity: string;
  validity_date: string;
  video: null;
}
export interface CourseProperties {
  course: CourseProps;
}
export interface AddedCourseProperties {
  course_id: number;
  license_no: number;
}

// export interface AllAddedCoursesProps extends Array<AddedCourseProperties> {}

// export interface AllCoursesProps extends Array<CourseProperties> {}

/** True for completed onboarding step, false for not completed steps */
export const onboardingStepsDefault = {
  step_one: false,
  step_two: false,
  step_three: false,
  step_four: false,
  step_five: false,
  step_six: false,
};

const ImportCourse = () => {
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [routeBackLoader, setRouteBackLoader] = useState(false);

  // Third party hooks
  const history = useHistory();

  // Apps custom hooks
  const { courses, getCoursesLoader } = useGetCpCourses();
  const { run, data, isSuccess, isError, isLoading } = useAsync();

  // Contexts
  const { vleToken } = useVleTokenContext();
  const { addedCourses, setAddedCourses } = useAddedCoursesContext();

  const { courseProviderValue } = useMarketPlaceFilterContext();

  const { domain } = useDomainContext();

  useEffect(() => {
    let selectedCourses: any = [];

    addedCourses.map((addedCourse: any) => {
      let matchedCourse = courses.filter((course: any) => {
        return addedCourse.course_id === parseInt(course.id);
      });

      selectedCourses = [...selectedCourses, ...matchedCourse];

      return null;
    });

    setSelectedCourses(selectedCourses);
  }, [courses, addedCourses]);

  useEffect(() => {
    if (isSuccess) {
      Notification({
        message: "Import Processing! This may take a while",
        type: "success",
        title: "Success",
      });

      setAddedCourses([]);
    }
  }, [history, data, isSuccess, setAddedCourses]);

  useEffect(() => {
    if (addedCourses.length === 0) {
      history.push("/course-list/market-place");
    }
  }, [addedCourses.length, history]);

  useEffect(() => {
    if (isError) {
      setRouteBackLoader(false);

      Notification({
        message: "Somethin went wrong, please try again later",
        type: "warning",
        title: "Warning",
      });
    }
  }, [isError]);

  function addCoursesToAdmin() {
    if (addedCourses.length === 0) {
      Notification({
        message: "Please add atleast one free course to continue",
        type: "warning",
        title: "Warning",
      });

      return;
    }

    // const apiBody = {
    //   onboardingProcess: {
    //     step_two: 1,
    //   },
    //   course_list: addedCourses,
    // };

    const email = courseProviderValue?.split(" ")[0];
    const id = courseProviderValue?.split(" ")[1];

    const apiBody = {
      course_provider_account_id: id,
      course_provider_email: email,
      domain: domain,
      course_list: addedCourses,
    };

    setRouteBackLoader(true);

    run(
      client({
        method: "post",
        // endpoint: "api/akademy/shareCourseB2b?app=ms_teams",
        endpoint: "api/course-provider/add-courses",
        optional: {
          data: apiBody,
          token: vleToken,
        },
      })
    );
  }

  return (
    <Fragment>
      {isLoading || getCoursesLoader || routeBackLoader ? (
        <FullPageSpinner />
      ) : (
        <OnboardingWrapper>
          <Container>
            <AddContentTitle>Import Course</AddContentTitle>
            <AddContentTitleWrapper>
              <p css={{ color: "#616161", margin: "0", marginRight: "6px" }}>
                You can modify these later.
              </p>
              <Button
                variant="lightPurpleText"
                onClick={() =>
                  // history.push({ pathname: "/marketplaceTab", state: "isBack" })
                  history.goBack()
                }
              >
                Back
              </Button>
            </AddContentTitleWrapper>

            <Row>
              {selectedCourses.map((course: CourseProps) => {
                return <CourseAdd key={course.id} course={course} />;
              })}
            </Row>
          </Container>
          {isSuccess ? null : (
            <OnboardingFooter addCoursesToAdmin={addCoursesToAdmin} />
          )}
        </OnboardingWrapper>
      )}
    </Fragment>
  );
};

export default ImportCourse;
