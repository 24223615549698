/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { Fragment } from "react";

//ThirdParty imports
import { Tooltip } from "@material-ui/core";
import { useHistory, useRouteMatch } from "react-router-dom";

//Hooks imports
import { useShowTooltip } from "hooks";

//Styles imports
import { Button } from "styles/components.styles";
import {
  CourseBox,
  CourseInfo,
  CourseImage,
} from "screens/coursesTab/coursesTab.styles";
import {
  BtnText,
  ContentBtnWrap,
  ContentDetailTitle,
  ContentPrice,
} from "styles/courses.styles";

//Icon imports
import BundlesIcon from "assets/images/mykademyAdmin/layer.png";
import { Modal } from "components";
import { useModalContext } from "components/modal/modal";
import UnSubscribeBundlePopup from "../../unSubscribeBundlePopup/unSubscribeBundlePopup";
import {
  CrossedPrice,
  DiscountdPrice,
  DiscountedPriceWrapper,
} from "screens/coursesTab/learnerMarketPlace/coursesList/courseList.style";

import BundleActionButton from "../bundleActionButton";

const withModal =
  (Component: any) =>
  ({ ...props }: any) => {
    return (
      <Modal maxwidth="750px">
        <Component {...props} />
      </Modal>
    );
  };

const BundleCard = ({
  title,
  imageUrl,
  courseName,
  totalCourse,
  bundle,
}: any) => {
  const { isEllipseEnabled, titleRef } = useShowTooltip();

  const { setIsOpen } = useModalContext();

  const { id, currency } = bundle;

  const history = useHistory();

  const { url } = useRouteMatch();

  return (
    <Fragment>
      <UnSubscribeBundlePopup
        id={id}
        name={bundle?.c_title}
        setIsOpen={setIsOpen}
      />
      <CourseBox css={{ borderRadius: "6px" }} className="bundle-child">
        <CourseImage src={imageUrl} alt={courseName} />
        <CourseInfo>
          <Tooltip
            title={title}
            placement="top-start"
            disableHoverListener={!isEllipseEnabled}
          >
            <ContentDetailTitle
              ref={titleRef}
              css={{
                fontSize: "14px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {title}
            </ContentDetailTitle>
          </Tooltip>
          <div style={{ marginBottom: "20px" }}>
            <img
              src={BundlesIcon}
              style={{ width: "20px", height: "18px", marginRight: "6px" }}
              alt="bundle"
            />
            <span>{`${totalCourse} in 1`}</span>
          </div>

          <ContentPrice>
            {bundle?.c_is_free === "1" ? (
              "Free"
            ) : bundle?.c_discount && bundle?.c_discount === "0" ? (
              `${currency?.symbol}${bundle?.c_price}`
            ) : (
              <DiscountedPriceWrapper>
                <DiscountdPrice>
                  {currency?.symbol + bundle?.c_discount}
                </DiscountdPrice>
                <CrossedPrice>
                  {currency?.symbol + bundle?.c_price}
                </CrossedPrice>
              </DiscountedPriceWrapper>
            )}
          </ContentPrice>
          <ContentBtnWrap css={{ justifyContent: "flex-end", gap: "10px" }}>
            <BundleActionButton
              bundle={bundle}
              canUnenrollPaidCourse={true}
              setIsOpen={setIsOpen}
            />

            <Button
              variant="white"
              fontweight="600"
              onClick={() => {
                history.push(`${url}/${id}`);
              }}
            >
              <BtnText css={{ margin: "0" }}>Details</BtnText>
            </Button>
          </ContentBtnWrap>
        </CourseInfo>
      </CourseBox>
    </Fragment>
  );
};

export default withModal(BundleCard);
