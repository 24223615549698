import * as yup from "yup";

import { useState, useEffect } from "react";

function useEditPasswordSchema() {
  const [schema, setSchema] = useState<any>();

  useEffect(() => {
    const shapeObject: any = {
      "Current Password": yup
        .string()
        .required("Current Password cannot be empty"),
      "New Password": yup
        .string()
        .required("New password cannot be empty")
        .matches(
          /^((?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{8,20})$/,
          "Password must be between 8 and 20 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character."
        ),
      "Confirm New Password": yup
        .string()
        .required("Confirm password cannot be empty")
        .matches(
          /^((?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{6,15})$/,
          "Password must be between 8 and 20 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character."
        ),
    };

    let schema = yup.object().shape(shapeObject);

    setSchema(schema);
  }, []);

  return { schema };
}

export { useEditPasswordSchema };
