import styled from "@emotion/styled/macro";
import { Divider } from "@material-ui/core";

const ManualClaimContainer = styled.div`
  padding: 20px 25px;
  width: 30rem;
`;

const ManualClaimHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ManualClaimAchievementContainer = styled.div`
  background: #444791;
  color: white;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 4px;
  margin-top: 1rem;
`;

const ManualClaimOverallScoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  color: #676767;
  font-size: 1rem;
`;

const ManualClaimScoreDetails = styled.div`
  display: flex;
  color: #676767;
  margin-top: 0.6rem;
  gap: 0.5rem;
  font-size: 1rem;
`;

const ManualClaimBadgeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-top: 0.5rem;
  padding: 0.3rem;
`;

const ManualClaimBadgeImage = styled.img`
  height: 40px;
  width: 40px;
`;

const ManualClaimBadgeDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
`;

const ManualClaimClaimBadgeButton = styled.button`
  border: 1px solid #444791;
  border-radius: 15px;
  padding: 1px 8px;
  color: #444791;
  font-size: 0.8rem;
  cursor: pointer;
  background: rgba(68, 71, 145, 0.2);
`;

const ManualClaimStyledDivider = styled(Divider)`
  background: black;
  margin-top: 0.5rem;
`;

const ManualClaimStyledSpan = styled.span`
  color: #676767;
  cursor: pointer;
`;

const ManualClaimStyledSpanStrong = styled.strong`
  color: #676767;
`;
export {
  Divider,
  ManualClaimHeader,
  ManualClaimContainer,
  ManualClaimBadgeImage,
  ManualClaimStyledSpan,
  ManualClaimBadgeDetails,
  ManualClaimScoreDetails,
  ManualClaimStyledDivider,
  ManualClaimBadgeContainer,
  ManualClaimClaimBadgeButton,
  ManualClaimStyledSpanStrong,
  ManualClaimAchievementContainer,
  ManualClaimOverallScoreContainer,
};
