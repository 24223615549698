import {
  useGamificationContext,
  usePathwayTokenContext,
  useVleTokenContext,
} from "context";
import { useEffect, useState } from "react";

// Third party imports
import { useQuery } from "react-query";

// Util imports
import { client } from "utils/api-client";
import { Notification } from "utils/notification";

function useGetAllAccolades() {
  const [allAccolades, setAllAccolades] = useState<any>(null);

  const { pathwayToken } = usePathwayTokenContext();

  const { vleToken } = useVleTokenContext();

  const getAllAccolades = () => {
    return client({
      method: "get",
      endpoint: `api/learning-pathway/member-accolades?token=${pathwayToken}`,
      optional: {
        token: vleToken,
      },
    });
  };

  const { data, error, isLoading, isSuccess, isError, refetch } = useQuery(
    "allAccolades",
    getAllAccolades,
    {
      select(data) {
        return data?.data?.data?.memberAccolades?.map((pathway: any) => ({
          ...pathway,
          accolades: {
            badges: pathway?.accolades?.filter(
              (accolade: any) => accolade?.type === "Badge"
            ),
            courses: pathway?.accolades?.filter(
              (accolade: any) => accolade?.type === "Course"
            ),
          },
        }));
      },
      enabled: !!pathwayToken,
    }
  );

  useEffect(() => {
    if (isSuccess) {
      setAllAccolades(data);
    }
  }, [data, isSuccess, setAllAccolades]);

  useEffect(() => {
    if (isError) {
      Notification({
        message: "Unable to get accolades, please try again later",
        type: "warning",
        title: "Warning",
      });
    }
  }, [error, isError]);

  return {
    allAccolades,
    isLoading: isLoading,
    refetch,
  };
}

export { useGetAllAccolades };
