import { useMutation, useQueryClient } from "react-query";

// Context imports
import { useVleTokenContext } from "context";

// Utils imports
import { client } from "utils/api-client";
import { Notification } from "utils/notification";

function useClaimBadge() {
  const { vleToken } = useVleTokenContext();

  const queryClient = useQueryClient();

  const claimBadge = (data: any) => {
    return client({
      method: "post",
      endpoint: "api/reputation/badge-from-accolades",
      optional: {
        data: data,
        token: vleToken,
      },
    });
  };

  const { mutate, mutateAsync, isLoading } = useMutation(claimBadge, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries("recentCourse");
      queryClient.invalidateQueries("pathwayDetails");
      queryClient.invalidateQueries("allAccolades");

      queryClient.removeQueries("recentCourse");
      queryClient.removeQueries("pathwayDetails");
      queryClient.removeQueries("allAccolades");

      // const message = data.data.message;

      // Notification({
      // 	// message: message ? message : "Badge claimed Successfully",
      // 	message: "You have claimed your badge successfully",
      // 	type: "success",
      // 	title: "Success",
      // });
    },

    onError: (error: any) => {
      console.log({ error });

      const { message } = error?.response?.data;

      Notification({
        message: message
          ? message
          : "Unable to claim badge, please try again later",
        type: "warning",
        title: "Warning",
      });
    },
  });

  return { mutate, isLoading, mutateAsync };
}

export { useClaimBadge };
