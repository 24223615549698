import styled from "@emotion/styled/macro";

const SuccessBoxContainer = styled.div`
  background: #198754;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.3rem;
  width: 100%;
`;

const CloseButton = styled.p`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  width: 100%;
`;

const IconImage = styled.img`
  width: 50px;
  height: 50px;
`;

const SuccessText = styled.h2`
  font-size: 0.8rem;
  color: white !important;
`;

const MessageHeading = styled.p`
  font-size: 0.8rem;
  text-align: center;
`;
export {
  CloseButton,
  IconImage,
  MessageHeading,
  SuccessBoxContainer,
  SuccessText,
};
