import React, { Fragment } from "react";

//ThirdParty imports
import { Divider } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";

//Hooks imports
import { useIsMobileView, useShowTooltip } from "hooks";

//Styles imports
import {
  ManualClaimHeader,
  ManualClaimBadgeImage,
  ManualClaimBadgeDetails,
  ManualClaimScoreDetails,
  ManualClaimBadgeContainer,
  ManualClaimClaimBadgeButton,
  ManualClaimAchievementContainer,
  ManualClaimOverallScoreContainer,
} from "./manualClaim.styles";
import { Tooltip } from "@material-ui/core";

const AchievementBox = ({
  // isLoading,
  memberDetails,
  setIsClaimButtonClicked,
  setIsPopoverOpen,
  setOptionsToBeConverted,
  setOptionsAfterConversion,
}: any) => {
  const { isEllipseEnabled, titleRef } = useShowTooltip();

  const history = useHistory();

  const location = useLocation();

  const message = localStorage.getItem("achievementMessage");

  const { isMobileView } = useIsMobileView();

  return (
    <div
      style={{
        width: isMobileView ? "80vw" : "30rem",

        padding: "20px",
      }}
    >
      <Fragment>
        <ManualClaimHeader>
          <h2>My Achievements</h2>
          <span
            style={{
              cursor: "pointer",
            }}
            onClick={() => setIsPopoverOpen(false)}
          >
            Close
          </span>
        </ManualClaimHeader>
        {message && (
          <ManualClaimAchievementContainer>
            <i>{message}</i>
          </ManualClaimAchievementContainer>
        )}
        <ManualClaimOverallScoreContainer>
          <span>Overall Score</span>
          <span
            style={{
              color: "#676767",
              cursor: "pointer",
              display:
                location.pathname === "/myAchievements"
                  ? "none"
                  : "inline-block",
            }}
            onClick={() => history.push("/myAchievements")}
          >
            View All
          </span>
        </ManualClaimOverallScoreContainer>
        <ManualClaimScoreDetails>
          <span>
            <strong>{memberDetails?.totalPoint} </strong>
            points
          </span>
          <span>|</span>
          <span>
            <strong>{memberDetails?.totalActivities} </strong>
            activities done
          </span>
        </ManualClaimScoreDetails>
        <Divider
          style={{
            background: "black",
            marginTop: "0.5rem",
          }}
        />

        <div
          style={{
            height: memberDetails?.allBadges?.length > 5 ? "420px" : "auto",
            overflow: "auto",
          }}
        >
          {memberDetails?.allBadges?.map((badge: any, index: any) => {
            return (
              <Fragment key={index}>
                <ManualClaimBadgeContainer>
                  <ManualClaimBadgeImage src={badge?.icon} alt={badge?.title} />
                  <ManualClaimBadgeDetails>
                    <span>
                      {badge?.title} <strong>({badge?.achievedCount})</strong>
                    </span>
                    <div
                      style={{
                        display: "flex",
                        gap: "0.3rem",
                      }}
                    >
                      <span
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {badge?.pointCriteria?.pointPerBadge} points
                      </span>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.3rem",
                        }}
                      >
                        {badge?.badgesCriteria?.map((item: any, i: any) => {
                          return (
                            <Fragment key={i}>
                              <span>|</span>
                              {`${item.quantityPerBadge} ${item.title}`}
                            </Fragment>
                          );
                        })}
                      </span>

                      {badge?.eventCriteria ? (
                        <Fragment>
                          <span>|</span>
                          <Tooltip
                            title={badge?.eventCriteria?.eventName}
                            placement="top-start"
                            disableHoverListener={!isEllipseEnabled}
                          >
                            <div
                              ref={titleRef}
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "18rem",
                              }}
                            >
                              {badge?.eventCriteria?.eventName}
                            </div>
                          </Tooltip>
                        </Fragment>
                      ) : null}
                    </div>
                    <ManualClaimClaimBadgeButton
                      onClick={() => {
                        setOptionsAfterConversion(
                          badge?.optionsAfterConversion
                        );
                        setOptionsToBeConverted(badge?.optionsToBeConverted);
                        setIsClaimButtonClicked(true);
                      }}
                      style={{
                        display: badge?.canClaim ? "block" : "none",
                      }}
                    >
                      Claim badge
                    </ManualClaimClaimBadgeButton>
                  </ManualClaimBadgeDetails>
                </ManualClaimBadgeContainer>
                <Divider
                  style={{
                    background: "black",
                    marginTop: "0.3rem",
                  }}
                />
              </Fragment>
            );
          })}
        </div>
      </Fragment>
    </div>
  );
};

export default AchievementBox;
